import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'

import { debounce } from 'throttle-debounce'

import { removePxUnit } from './css-unit'
import { useFocusRingColorForNode } from './focus'
import type { TextProps } from '../components/text/text'

type WidthBasedProps = { maxWidth: string; truncateLines?: never }

type HeightBasedProps = { truncateLines?: number; maxWidth?: never }

type Props = { children?: TextProps['children'] } & (WidthBasedProps | HeightBasedProps)

export const useIsTruncating = ({ maxWidth, truncateLines = 0, children }: Props) => {
    const truncateContentRef = useRef<HTMLElement | null>(null)
    const [textContent, setTextContent] = useState('')
    const [isTruncating, setIsTruncating] = useState(false)
    const [{ focusRingColor, minimal, alpha }, setNodeForCurrentColor] = useFocusRingColorForNode()

    const handleTruncation = useCallback(() => {
        if (truncateContentRef.current) {
            if (truncateLines) {
                // When rendering on Chrome, the bounding rect needs an extra 1.2px > lineHeight, on FireFox this is 1px. Lets set it to 2 to be on the safe side
                const offsetForRenderingBoundingRect = 2
                const boundingRectHeight = truncateContentRef.current.getBoundingClientRect().height
                const truncatedRectHeight = truncateLines
                    ? removePxUnit(getComputedStyle(truncateContentRef.current).lineHeight) * truncateLines
                    : boundingRectHeight

                // Show tooltip if bounding rectangle that contains the text is taller than height of truncated lines
                setIsTruncating(boundingRectHeight - offsetForRenderingBoundingRect > truncatedRectHeight)
            } else if (maxWidth) {
                const boundingRectWidth = truncateContentRef.current.getBoundingClientRect().width

                // Show tooltip if bounding rectangle that contains the text is wider than the width of the container
                setIsTruncating(boundingRectWidth > removePxUnit(maxWidth))
            }

            setTextContent(truncateContentRef.current.textContent ?? '')
        }
    }, [maxWidth, truncateLines])

    useLayoutEffect(() => {
        handleTruncation()
    }, [handleTruncation])

    useEffect(() => {
        const handleResize = debounce(10, handleTruncation)

        window.addEventListener('resize', handleResize, false)

        return () => {
            window.removeEventListener('resize', handleResize, false)
        }
    }, [children, setNodeForCurrentColor, handleTruncation])

    return {
        truncateContentRef: useCallback(
            (node: HTMLElement | null) => {
                setNodeForCurrentColor(node)
                truncateContentRef.current = node
            },
            [setNodeForCurrentColor],
        ),
        isTruncating,
        textContent,
        nodeForCurrentColor: { focusRingColor, minimal, alpha },
    }
}
