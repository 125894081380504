import { useCallback } from 'react'

import type { Toast, useToaster } from 'react-hot-toast'

import { createToastContentId } from './utils'
import { useTheme } from '../../utils/use-theme'
import { Box } from '../box/box'

type Props = Pick<ReturnType<typeof useToaster>['handlers'], 'calculateOffset' | 'updateHeight'> & {
    toast: Toast
}

export const ToastWrapper = ({ toast, calculateOffset, updateHeight }: Props) => {
    const theme = useTheme()

    const offset = calculateOffset(toast, {
        defaultPosition: 'bottom-center',
        reverseOrder: false,
        gutter: theme.grid,
    })

    const ref = useCallback(
        (el: HTMLElement | null) => {
            if (el && !toast.height) {
                const height = el.getBoundingClientRect().height

                updateHeight(toast.id, height)
            }
        },
        [toast.height, toast.id, updateHeight],
    )

    const animationDurationAndTiming = `${theme.duration.fast} ${theme.timingFunction['ease-out']}`
    const styles = {
        /**
         * Passing the `bottom` CSS style here solves a positioning issue. Since the toasts can have
         * different heights, it's better to provide the bottom position of the toast on it directly,
         * not on the wrapper element. Due to this approach, it doesn't matter that the toast has a 100px
         * height or a 40px height, the toast will be positioned to the bottom properly.
         */
        bottom: theme.space.large,
        opacity: toast.visible ? 1 : 0,
        position: 'absolute',
        transform: `translateY(-${offset}px)`,
        transition: `transform ${animationDurationAndTiming}, opacity ${animationDurationAndTiming}`,
        animation: `toastEnter ${theme.duration.slow}`,
    } as const

    return (
        <Box
            key={toast.id}
            id={toast.id}
            ref={ref}
            style={styles}
            {...toast.ariaProps}
            aria-labelledby={createToastContentId(toast)}
        >
            {typeof toast.message === 'function' ? toast.message(toast) : toast.message}
        </Box>
    )
}

ToastWrapper.displayName = 'ToastWrapper'
