import { forwardRef } from "react";
import { IconProps } from "../shared";
import { Icon } from "../icon";
export const IconAlertCircle = forwardRef<HTMLElement, IconProps>(
    (props, ref) => (
        <Icon ref={ref} {...props}>
            <path
                fill="#111928"
                fillRule="evenodd"
                d="M12 4c4.416 0 8 3.584 8 8s-3.584 8-8 8-8-3.584-8-8 3.584-8 8-8zm0 10.667a1.333 1.333 0 100 2.666 1.333 1.333 0 000-2.666zm-.889-1.778V7.556h1.778v5.333H11.11z"
                clipRule="evenodd"
            />
        </Icon>
    )
);
IconAlertCircle.displayName = "IconAlertCircle";
