import { forwardRef } from 'react'

import type { Placement } from '@floating-ui/react'

import { px } from '../../utils/css-unit'
import { Box } from '../box/box'

type Props = {
    placement?: Placement
    left?: string
    top?: string
}

const arrowSize = {
    width: 14,
    height: 7,
} as const

// eslint-disable-next-line complexity
const getPositionByPlacement = ({
    placement,
    top,
    left,
}: {
    placement: Placement | undefined
    left?: string
    top?: string
}) => {
    const axisYoffset = `-${arrowSize.height - 5}px`
    const axisXoffset = `-${arrowSize.width - arrowSize.height / 2 - 5}px`

    switch (placement) {
        case 'top': {
            return {
                bottom: axisYoffset,
                left,
            }
        }
        case 'top-start': {
            return {
                bottom: axisYoffset,
                left,
            }
        }
        case 'top-end': {
            return {
                bottom: axisYoffset,
                left,
            }
        }
        case 'bottom': {
            return {
                top: axisYoffset,
                transform: 'rotate(180deg)',
                left,
            }
        }
        case 'bottom-start': {
            return {
                top: axisYoffset,
                left,
                transform: 'rotate(180deg)',
            }
        }
        case 'bottom-end': {
            return {
                top: axisYoffset,
                left,
                transform: 'rotate(180deg)',
            }
        }
        case 'right': {
            return {
                left: axisXoffset,
                transform: 'rotate(90deg)',
                top,
            }
        }
        case 'right-start': {
            return {
                left: axisXoffset,
                transform: 'rotate(90deg)',
                top,
            }
        }
        case 'right-end': {
            return {
                left: axisXoffset,
                transform: 'rotate(90deg)',
                top,
            }
        }
        case 'left': {
            return {
                right: axisXoffset,
                transform: 'rotate(-90deg)',
                top,
            }
        }
        case 'left-start': {
            return {
                right: axisXoffset,
                transform: 'rotate(-90deg)',
                top,
            }
        }
        case 'left-end': {
            return {
                right: axisXoffset,
                transform: 'rotate(-90deg)',
                top,
            }
        }
        default: {
            return {
                display: 'none',
            }
        }
    }
}

export const InfoTipArrow = forwardRef<HTMLElement, Props>(({ placement, left, top }, ref) => {
    const stylesArrow = {
        width: px(arrowSize.width),
        height: px(arrowSize.height),
        fill: 'backgroundInverted',
        position: 'absolute',
        lineHeight: 'xx-small',
        ...getPositionByPlacement({ placement, left, top }),
    } as const

    return (
        <Box css={stylesArrow} ref={ref}>
            <svg style={{ width: '100%', height: '100%' }}>
                <path d="M5.47248 6.30384C6.27829 7.23206 7.72172 7.23206 8.52753 6.30384L14 0H0L5.47248 6.30384Z" />
            </svg>
        </Box>
    )
})

InfoTipArrow.displayName = 'InfoTipArrow'
