import { forwardRef } from "react";
import { IconProps } from "../shared";
import { Icon } from "../icon";
export const IconWarning = forwardRef<HTMLElement, IconProps>((props, ref) => (
    <Icon ref={ref} {...props}>
        <path
            fill="#111928"
            fillRule="evenodd"
            d="M11.372 4.5a1 1 0 011.732 0l7.505 13a1 1 0 01-.866 1.5H4.733a1 1 0 01-.867-1.5l7.506-13zm1.755 3.833H11.35v5.334h1.777V8.333zm-2.223 8a1.333 1.333 0 112.667 0 1.333 1.333 0 01-2.667 0z"
            clipRule="evenodd"
        />
    </Icon>
));
IconWarning.displayName = "IconWarning";
