export const paramsSerializer = (params: Record<string, unknown>) => {
    const paramsNormalized = Object.entries(params).flatMap(([key, value]) => {
        if (value === undefined) {
            return []
        }

        if (Array.isArray(value)) {
            return value
                .map((singleValue) => (singleValue === undefined ? singleValue : [key, singleValue]))
                .filter((mappedSingleValue) => !!mappedSingleValue)
        }

        return [[key, value]]
    })

    return new URLSearchParams(paramsNormalized).toString()
}
