import { forwardRef } from 'react'

import { buttonLabel } from './constants/button-label'
import { Box } from '../box/box'
import { ButtonMinimal } from '../button/button-minimal'
import { IconButtonMinimalCore } from '../icon-button/icon-button-minimal-core'
import { IconInfo } from '../icons/generated/info'
import type { PopoverTriggerRenderProps } from '../popover/types'

export type Props = PopoverTriggerRenderProps & {
    ariaControls?: string
    ariaExpanded?: boolean
    label?: string
}

const stylesTriggerProxy = {
    width: 'fit-content',
    height: 'fit-content',
} as const

export const InfoTipTrigger = forwardRef<HTMLButtonElement, Props>(
    ({ ariaControls, ariaExpanded, disabled, onClick, label }, ref) => (
        <Box css={stylesTriggerProxy}>
            {label ? (
                <ButtonMinimal
                    aria-controls={ariaControls}
                    aria-expanded={ariaExpanded}
                    ref={ref}
                    onClick={onClick}
                    prefix={<IconInfo />}
                    position="relative"
                >
                    {label}
                </ButtonMinimal>
            ) : (
                <IconButtonMinimalCore
                    ref={ref}
                    size="small"
                    aria-controls={ariaControls}
                    aria-expanded={ariaExpanded}
                    aria-label={buttonLabel}
                    disabled={disabled}
                    isTooltipVisible={false}
                    icon={<IconInfo />}
                    onClick={onClick}
                    label={buttonLabel}
                    position="relative"
                />
            )}
        </Box>
    ),
)

InfoTipTrigger.displayName = 'InfoTipTrigger'
