export const remove = <T>(array: ReadonlyArray<T>, index: number): Array<T> =>
    index < 0 || index > array.length - 1 ? [...array] : [...array.slice(0, index), ...array.slice(index + 1)]

// Source: https://stackoverflow.com/a/53187807
/**
 * Returns the index of the last element in the array where predicate is true, and -1
 * otherwise.
 *
 * @param array The source array to search in
 * @param predicate find calls predicate once for each element of the array, in descending
 * order, until it finds one where predicate returns true. If such an element is found,
 * findLastIndex immediately returns that element index. Otherwise, findLastIndex returns -1.
 */
export const findLastIndex = <T>(
    array: Array<T>,
    predicate: (value: T, index: number, obj: Array<T>) => boolean,
): number => {
    let arrayLength = array.length

    // eslint-disable-next-line no-plusplus
    while (arrayLength--) {
        if (predicate(array[arrayLength] as T, arrayLength, array)) {
            return arrayLength
        }
    }

    return -1
}
