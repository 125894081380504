import { flushSync } from 'react-dom'

// Borrowed from https://github.com/TanStack/router/blob/21743e263df6eb08c3685ffea937d3c4a1637eed/packages/react-router/src/router.ts#L1844-L1853
export const startViewTransition = (callback: () => void) => {
    if (
        typeof document !== 'undefined' &&
        'startViewTransition' in document &&
        typeof document.startViewTransition === 'function'
    ) {
        document.startViewTransition(() => {
            flushSync(callback)
        })
    } else {
        callback()
    }
}
