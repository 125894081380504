import { splitProps } from 'reakit-utils/splitProps'

type Props = Record<string, unknown>

// eslint-disable-next-line @eslint-react/hooks-extra/no-redundant-custom-hook
export const useReakitHook = (hook: unknown, props: Props, ownOptions?: Props): Props => {
    // @ts-expect-error Object is of type 'unknown'
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const [options, htmlProps] = splitProps(props, hook.__keys) // eslint-disable-line no-underscore-dangle
    // the only className reakit creates is `hidden`. Since the name conflicts
    // with bootstrap css classes we remove it here.
    // @ts-expect-error Object is of type 'unknown'
    const { className: classNameIgnored, ...result } = hook({ ...options, ...ownOptions }, htmlProps)

    return { ...result, className: props.className }
}
