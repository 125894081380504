import type { RefObject } from 'react'
import { useEffect } from 'react'

import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

export function usePreventScroll({ targetRef, enabled }: { targetRef: RefObject<HTMLElement>; enabled: boolean }) {
    useEffect(() => {
        const element = targetRef.current

        if (!element || !enabled) {
            return undefined
        }

        disableBodyScroll(element, { reserveScrollBarGap: true })

        return () => enableBodyScroll(element)
    }, [targetRef, enabled])
}
