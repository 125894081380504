type Duration = `${number}s` | `.${number}`

const definition = {
    fast: '.225s',
    normal: '.3s',
    slow: '.45s',
    slowest: '1s',
} satisfies Record<string, Duration>

export const duration: { [K in keyof typeof definition]: Duration } = definition
