import { forwardRef } from 'react'

import type { Props as ButtonBaseProps } from './button-base'
import { ButtonBase } from './button-base'

const getBackgroundColor = (variant: ButtonBaseProps['variant']) => {
    if (!variant || variant === 'brand') {
        return 'brand'
    }

    if (variant === 'danger') {
        return 'dangerActive'
    }

    return `${variant}Highlighted` as const
}

const getHoveredBackgroundColor = (variant: ButtonBaseProps['variant']) => {
    if (!variant || variant === 'brand') {
        return 'brandHighlighted'
    }

    if (variant === 'danger') {
        return 'dangerSecondaryActive'
    }

    return `${variant}Active` as const
}

type Props = Omit<ButtonBaseProps, 'color' | 'backgroundColor'>

export const Button = forwardRef<HTMLButtonElement, Props>(({ variant = 'brand', css, ...props }, ref) => (
    <ButtonBase
        ref={ref}
        backgroundColor={getBackgroundColor(variant)}
        borderColor="transparent"
        color="background"
        variant={variant}
        isButtonWithLabel
        css={[
            {
                '&:hover': {
                    ':not([aria-disabled="true"])': {
                        backgroundColor: getHoveredBackgroundColor(variant),
                        color: 'background',
                    },
                },
                '&:focus': {
                    ':not([aria-disabled="true"])': {
                        backgroundColor: getBackgroundColor(variant),
                    },
                    color: 'background',
                },
                '&:active': {
                    ':not([aria-disabled="true"])': {
                        backgroundColor: variant,
                        color: 'background',
                    },
                },
                '&[aria-disabled="true"]': props.isLoading
                    ? /**
                       * Here an empty object needs to be passed, otherwise, the styling from the
                       * `ButtonBase` component won't be inherited.
                       */
                      {}
                    : {
                          backgroundColor: 'backgroundEmphasis',
                          color: 'textMuted',
                          borderColor: 'backgroundEmphasis',
                      },
            },
            css,
        ]}
        {...props}
    />
))

Button.displayName = 'Button'
