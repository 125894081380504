import type { ComponentProps, ReactElement } from 'react'
import { useEffect, useState } from 'react'

import { CircularProgress } from '../../components/circular-progress/circular-progress'
import { Drawer } from '../../components/drawer/drawer'
import { Flex } from '../../components/flex/flex'
import { Grid } from '../../components/grid/grid'
import { ModalDialog } from '../../components/modal-dialog/modal-dialog'

const Deferred = ({ children, timeout = 250 }: { children: ReactElement; timeout?: number }) => {
    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoaded(true)
        }, timeout)

        return () => clearTimeout(timer)
    }, [timeout])

    return loaded ? children : null
}

export const ModalDialogLoader = (props: Partial<ComponentProps<typeof ModalDialog>> & { onClose: () => void }) => {
    const title = props.title ?? 'Loading'

    return (
        <ModalDialog
            applyAction={null}
            cancelAction={{
                label: 'Close',
                onClick: props.onClose,
            }}
            {...props}
            onClose={props.onClose}
            title={title}
        >
            <Grid justifyContent="center" alignItems="center">
                <CircularProgress
                    size="x-large"
                    aria-label="Loading Dialog Content"
                    role="alert"
                    aria-live="assertive"
                />
            </Grid>
        </ModalDialog>
    )
}

export const DrawerLoader = (props: Partial<ComponentProps<typeof Drawer>> & { onClose: () => void }) => {
    const drawerProps = {
        ...props,
        onClose: props.onClose,
        title: props.title ?? 'Loading',
        placement: props.placement ?? 'right',
    }

    return (
        <Drawer {...drawerProps}>
            <Grid justifyContent="center" alignItems="center" height="100%">
                <CircularProgress
                    size="x-large"
                    aria-label="Loading Drawer Content"
                    role="alert"
                    aria-live="assertive"
                />
            </Grid>
        </Drawer>
    )
}

type Props = {
    size?: ComponentProps<typeof CircularProgress>['size']
} & ComponentProps<typeof Flex>

export const DefaultLoader = ({ size = 'x-large', paddingTop = 'x-large', ...props }: Props) => (
    <Deferred>
        <Flex
            alignItems="center"
            flexDirection="column"
            justifyContent="center"
            paddingTop={paddingTop}
            height="100%"
            {...props}
        >
            <CircularProgress size={size} aria-label="Loading Data" role="alert" aria-live="assertive" />
        </Flex>
    </Deferred>
)
