import type { Colord } from 'colord'
import { colord, extend } from 'colord'
// there is no extension for this import
// eslint-disable-next-line import/extensions
import mixPlugin from 'colord/plugins/mix'

extend([mixPlugin])

/** @public */
export const lighten = (color: string | Colord, amount?: number) => colord(color).lighten(amount)

export const setAlpha = (color: string | Colord, alpha?: number) => colord(color).alpha(alpha ?? 1)

export const readableColor = (color: string | Colord) => (colord(color).isDark() ? 'textInverted' : 'text')

export const isDark = (value: Colord | string) => colord(value).isDark()

/** @public */
export const isValid = (color: string) => colord(color).isValid()
