import { ModalDialog } from './modal-dialog'
import { IconAlertCircle } from '../icons/generated/alert-circle'
import { Stack } from '../stack/stack'
import { Text } from '../text/text'

export type AlertDialogProps = {
    message: string
    title?: string
    onClose: () => void
    disablePortal?: boolean
}

export const AlertDialog = ({ message, onClose, disablePortal, title = 'Alert' }: AlertDialogProps) => (
    <ModalDialog
        title={title}
        disablePortal={disablePortal}
        cancelAction={null}
        applyAction={{ label: 'Got it', onClick: onClose, autoFocus: true }}
        onClose={onClose}
        size="small"
        role="alertdialog"
    >
        <Stack spacing="x-small" alignItems="center" orientation="horizontal">
            <IconAlertCircle color="brand" />
            <Text>{message}</Text>
        </Stack>
    </ModalDialog>
)

AlertDialog.displayName = 'AlertDialog'
