
// This file is generated. Do not modify it manually.
// Run 'pnpm nx run legacy-datatap-paths:generate-get-path-to' to regenerate this file.

export const getPathTo = {
  administration: () => `/administration/`,
  authorizationHistory: ({ authorizationId }: { authorizationId: number }) => `/authorization/${authorizationId}/history/`,
  authorizationsAdd: () => `/core/authorization/add/`,
  authorizationsList: () => `/authorizations/list/`,
  connectorEngine: () => `/connector-engine/`,
  connectorEngineConnector: ({ connectorSlug }: { connectorSlug: string }) => `/connector-engine/${connectorSlug}/`,
  connectorEngineConnectorConfig: ({ connectorSlug }: { connectorSlug: string }) => `/connector-engine/${connectorSlug}/config/`,
  connectorEngineConnectorWorkflow: ({ connectorSlug, workflow }: { connectorSlug: string, workflow: string }) => `/connector-engine/${connectorSlug}/workflow/${workflow}/`,
  connectorEngineHelp: () => `/connector-engine/help/`,
  dataQuality: () => `/data-quality/`,
  dataQualityDuplicateIssue: ({ issueId }: { issueId: number }) => `/data-quality/duplication/issues/${issueId}/`,
  dataQualityDuplication: () => `/data-quality/duplication/`,
  dataQualityVolumeIssue: ({ issueId }: { issueId: number }) => `/data-quality/volume/issues/${issueId}/`,
  dataQualityVolume: () => `/data-quality/volume/`,
  dataQualityEditMonitor: ({ monitorUuid }: { monitorUuid: string }) => `/data-quality/monitors/${monitorUuid}/`,
  dataQualityUniversalMonitorRowDuplicates: () => `/data-quality/universal-monitors/duplicates/`,
  dataQualityUniversalMonitorVolumes: () => `/data-quality/universal-monitors/volumes/`,
  dataQualityValidity: () => `/data-quality/validity/`,
  dataQualityValidityIssue: ({ issueId }: { issueId: number }) => `/data-quality/validity/issues/${issueId}/`,
  datastreamDetails: ({ datastreamId, id }: { datastreamId: number, id: string }) => `/datastreams/${datastreamId}/details/${id}/`,
  datastreamExtractColumn: ({ datastreamId, extractId }: { datastreamId: number, extractId: number }) => `/datastreams/${datastreamId}/extracts/${extractId}/column/`,
  datastreamExtractEnrichment: ({ datastreamId, extractId, enrichmentId }: { datastreamId: number, extractId: number, enrichmentId: number }) => `/datastreams/${datastreamId}/extracts/${extractId}/enrich/${enrichmentId}/`,
  datastreamExtractPreview: ({ datastreamId, extractId }: { datastreamId: number, extractId: number }) => `/datastreams/${datastreamId}/extracts/${extractId}/`,
  datastreamExtractsList: ({ datastreamId }: { datastreamId: number }) => `/datastreams/${datastreamId}/extracts/`,
  datastreamExtractDuplicateIssue: ({ datastreamId, extractId, issueId }: { datastreamId: number, extractId: number, issueId: number }) => `/datastreams/${datastreamId}/extracts/${extractId}/issues/row-duplicates/${issueId}/`,
  datastreamExtractValueIssue: ({ datastreamId, extractId, issueId }: { datastreamId: number, extractId: number, issueId: number }) => `/datastreams/${datastreamId}/extracts/${extractId}/issues/values/${issueId}/`,
  datastreamExtractVolumeIssue: ({ datastreamId, extractId, issueId }: { datastreamId: number, extractId: number, issueId: number }) => `/datastreams/${datastreamId}/extracts/${extractId}/issues/volumes/${issueId}/`,
  datastreamHistory: ({ datastreamId }: { datastreamId: number }) => `/datastreams/${datastreamId}/history/`,
  datastreamHistoryRevision: ({ datastreamId, revisionId }: { datastreamId: number, revisionId: number }) => `/datastreams/${datastreamId}/history/${revisionId}/`,
  datastreamIssues: ({ datastreamId }: { datastreamId: number }) => `/datastreams/${datastreamId}/issues/`,
  datastreamMapping: ({ datastreamId }: { datastreamId: number }) => `/datastreams/${datastreamId}/mapping/`,
  datastreamOverview: ({ datastreamId }: { datastreamId: number }) => `/datastreams/${datastreamId}/`,
  datastreamRetention: ({ datastreamId }: { datastreamId: number }) => `/datastreams/${datastreamId}/retention/`,
  datastreams: () => `/datastreams/`,
  datastreamsAdd: () => `/core/apidatastream/add/`,
  datastreamSettings: ({ datastreamId }: { datastreamId: number }) => `/datastreams/${datastreamId}/settings/`,
  datastreamSetupDestinations: ({ datastreamId }: { datastreamId: number }) => `/datastream_setup/${datastreamId}/destinations/`,
  datastreamsList: () => `/datastreams/list/`,
  datastreamWorkflow: ({ datastreamId }: { datastreamId: number }) => `/datastreams/${datastreamId}/workflow/`,
  destination: () => `/target/basetarget/add/`,
  destinationsAdd: () => `/target/basetarget/add/`,
  downloadJobArtifact: ({ artifactId }: { artifactId: number }) => `/api/web-app/artifact/${artifactId}/download/`,
  enrichmentsImport: () => `/enrichments/enrichment/import/`,
  exploreUpsell: () => `/feature/explore/`,
  extractionLogsList: () => `/extraction-logs/list/`,
  extractsList: () => `/extracts/list/`,
  home: () => `/home/`,
  login: ({ next }: { next?: string } = {}) => `/login/${next ? `?next=${next}` : ''}`,
  redirected: () => `/redirected/`,
  mappingTables: () => `/mapping/mappingtable/`,
  mediaScreenshots: () => `/imgsrv/mediascreenshot/`,
  oauthInvite: () => `/oauth2/invite/`,
  oauthReauthorize: ({ id }: { id: string }) => `/oauth2/reauthorize/${id}/`,
  oauthReauthorizeEmailForm: ({ id }: { id: string }) => `/oauth2/reauthorize/${id}/email-form/`,
  performanceManager: () => `/performance-manager/`,
  presentUpsell: () => `/feature/present/`,
  profile: () => `/accounts/profile/`,
  targetColumns: () => `/target/targetcolumns/`,
  transferList: () => `/target/list/`,
  usageManager: () => `/usage-manager/`,
  usageManagerUnsubscribe: () => `/usage-manager/unsubscribe/`,
  valueTables: () => `/mapping/valuetable/`,
  workspace: ({ stackSlug }: { stackSlug: string }) => `/activity/${stackSlug}/`,
  workspaceDetails: ({ stackSlug }: { stackSlug: string }) => `/activity/${stackSlug}/details/`,
  workspaceJobDetails: ({ stackSlug, jobId }: { stackSlug: string, jobId: unknown }) => `/activity/${stackSlug}/details/${jobId}/`,
  workspaceMappings: () => `/adverity_workspace/adverityworkspacemappingtable/`,
  workspaceMappingsDetails: ({ mappingTableId }: { mappingTableId: number }) => `/adverity_workspace/adverityworkspacemappingtable/mappings/${mappingTableId}/`
};
