/* eslint-disable functional/immutable-data */

import type { Middleware } from '@floating-ui/react'
import { flip, offset, shift, size } from '@floating-ui/react'

import { useOffset } from '../../utils/use-modifiers'

export const usePopoverDefaultMiddleware: () => Array<Middleware> = () => [
    shift(),
    flip({
        fallbackAxisSideDirection: 'start', // Let popover find the best placement (right, left) to  fit content if "bottom" (default, preferred) and top (flip) don't have enough space
    }),
    offset({ mainAxis: useOffset() }),
]

/** @public Marking as public for now -- However shall be used used as defaultMiddleware and not exposed. */
export const usePopoverContentResizeMiddleware: () => Array<Middleware> = () => [
    ...usePopoverDefaultMiddleware(),
    /**
     * Middleware that tells the popover to re-size itself to fit the content if it has changed after the popover is open, for example after an image has finished loading in the popover
     */
    size({
        apply({ elements, x, y }: { elements: { floating: { style: CSSStyleDeclaration } }; x: number; y: number }) {
            Object.assign(elements.floating.style, {
                top: `${y}px`,
                left: `${x}px`,
                bottom: 'auto',
                right: 'auto',
                width: 'auto',
            })
        },
    }),
]
