/**
 * Formats a relative time based on the difference between the given date and the current time.
 * @param date The date to format.
 * @returns The formatted relative time.
 */
// eslint-disable-next-line max-statements
export const formatRelativeTime = (date: Date) => {
    const now = Date.now()
    const diffInSeconds = Math.floor((now - date.getTime()) / 1000)

    const relativeTime = new Intl.RelativeTimeFormat('en', { numeric: 'auto' })

    if (diffInSeconds < 60) {
        return relativeTime.format(-diffInSeconds, 'second')
    }

    const diffInMinutes = Math.floor(diffInSeconds / 60)

    if (diffInMinutes < 60) {
        return relativeTime.format(-diffInMinutes, 'minute')
    }

    const diffInHours = Math.floor(diffInMinutes / 60)

    if (diffInHours < 24) {
        return relativeTime.format(-diffInHours, 'hour')
    }

    const diffInDays = Math.floor(diffInHours / 24)

    if (diffInDays < 7) {
        return relativeTime.format(-diffInDays, 'day')
    }

    const diffInWeeks = Math.floor(diffInDays / 7)

    if (diffInWeeks < 4) {
        return relativeTime.format(-diffInWeeks, 'week')
    }

    const diffInMonths = Math.floor(diffInDays / 30)

    if (diffInMonths < 12) {
        return relativeTime.format(-diffInMonths, 'month')
    }

    const diffInYears = Math.floor(diffInDays / 365)

    return relativeTime.format(-diffInYears, 'year')
}
