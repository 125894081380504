/**
 * Scale
 */

const XXXSMALL = 'xxx-small'
const XXSMALL = 'xx-small'
const XSMALL = 'x-small'
const SMALL = 'small'
const MEDIUM = 'medium'
const LARGE = 'large'
const XLARGE = 'x-large'
const XXLARGE = 'xx-large'
const XXXLARGE = 'xxx-large'

/** @public only used in tests */
export const scale = {
    XXXSMALL,
    XXSMALL,
    XSMALL,
    SMALL,
    MEDIUM,
    LARGE,
    XLARGE,
    XXLARGE,
    XXXLARGE,
} as const

export const space = {
    XXSMALL,
    XSMALL,
    SMALL,
    MEDIUM,
    LARGE,
    XLARGE,
    XXLARGE,
} as const

export const sizes = {
    XSMALL,
    SMALL,
    MEDIUM,
    LARGE,
    XLARGE,
} as const

const SUCCESS = 'success'
const WARNING = 'warning'
const DANGER = 'danger'

/** @public only used in tests */
export const variants = {
    SUCCESS,
    WARNING,
    DANGER,
} as const

const METRIC = 'metric'
const DIMENSION = 'dimension'

/** @public only used in tests */
export const schemaMapping = {
    METRIC,
    DIMENSION,
} as const

export const limitedSizes = ['small', 'medium', 'large'] as const
