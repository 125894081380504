import type { ReactNode } from 'react'
import { forwardRef } from 'react'

import type { ColorProps } from '../../system/styles/color'
import type { SpaceProps } from '../../system/styles/space'
import type { TextTransform, TypographyProps } from '../../system/styles/typography'
import { assertNever } from '../../utils/assert-never'
import type { Props as BoxProps } from '../box/box'
import { Box } from '../box/box'

type LevelProp = 1 | 2 | 3

type CommonTextProps = Pick<TypographyProps, 'textAlign' | 'fontWeight'> & {
    textTransform?: TextTransform
}

export type Props = ColorProps &
    CommonTextProps &
    SpaceProps &
    Pick<BoxProps, 'css'> & {
        as?: BoxProps['as']
        level: LevelProp
        children: ReactNode
    }

const getFontSizeByHeadingLevel = (headingLevel: LevelProp) => {
    switch (headingLevel) {
        case 1: {
            return {
                fontSize: 'xx-large',
                lineHeight: 'xx-large',
            } as const
        }
        case 2: {
            return {
                fontSize: 'x-large',
                lineHeight: 'x-large',
            } as const
        }
        case 3: {
            return {
                fontSize: 'large',
                lineHeight: 'large',
            } as const
        }
        default: {
            return assertNever(headingLevel)
        }
    }
}

export const Heading = forwardRef<HTMLElement, Props>(
    ({ children, level, as, css, textAlign, textTransform, fontWeight = 'regular', ...props }, ref) => {
        const tagName = as ?? (`h${level}` as const)

        return (
            <Box
                ref={ref}
                as={tagName}
                display="block"
                css={[
                    { fontWeight: fontWeight as never, fontFamily: 'body', textAlign, textTransform },
                    getFontSizeByHeadingLevel(level),
                    css,
                ]}
                {...props}
            >
                {children}
            </Box>
        )
    },
)

Heading.displayName = 'Heading'
