type TimingFunction = 'linear' | 'ease-in' | 'ease-out' | `cubic-bezier(${number},${number},${number},${number})`

const definition = {
    linear: 'linear',
    'ease-in': 'ease-in',
    'ease-out': 'ease-out',
    'ease-in-out': 'cubic-bezier(0.45,0.05,0.55,0.95)',
} as const satisfies Record<string, TimingFunction>

export const timingFunction: { [K in keyof typeof definition]: TimingFunction } = definition
