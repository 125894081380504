import { forwardRef } from "react";
import { IconProps } from "../shared";
import { Icon } from "../icon";
export const IconArrowLeft = forwardRef<HTMLElement, IconProps>(
    (props, ref) => (
        <Icon ref={ref} {...props}>
            <path
                fill="#111928"
                fillRule="evenodd"
                d="M11 19l-7-7 7-7 1.42 1.42L7.83 11H20v2H7.83l4.58 4.59L11 19z"
                clipRule="evenodd"
            />
        </Icon>
    )
);
IconArrowLeft.displayName = "IconArrowLeft";
