import { forwardRef } from "react";
import { IconProps } from "../shared";
import { Icon } from "../icon";
export const IconInfo = forwardRef<HTMLElement, IconProps>((props, ref) => (
    <Icon ref={ref} {...props}>
        <path
            fill="#111928"
            fillRule="evenodd"
            d="M13 17h-2v-6h2v6zm.5-9.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z"
            clipRule="evenodd"
        />
        <path
            fill="#111928"
            fillRule="evenodd"
            d="M12 20a8 8 0 100-16 8 8 0 000 16zm0 2c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z"
            clipRule="evenodd"
        />
    </Icon>
));
IconInfo.displayName = "IconInfo";
