import type { ColorsAccessPaths } from './generated/token-access-paths'
import type { ResponsiveValue } from '../../system/styled-system-re-exported-types'
import { setAlpha } from '../../utils/color'
import type { CssGlobals } from '../../utils/css-unit'

const text = '#111928'

type HexString = `#${string}`

export type Color = CssGlobals | HexString | 'currentcolor' | 'transparent'

const definition = {
    // Brand
    brand: '#1839A0',
    brandHighlighted: '#2950E3',
    brandActive: '#4071F7',
    brandSecondary: '#F4F8FF',
    brandSecondaryHighlighted: '#E5EEFF',
    brandSecondaryActive: '#D1E1FF',
    brandText: '#182447',
    brandAccent: '#00FFC5',

    // Variants
    success: '#397546',
    successHighlighted: '#4D9B5F',
    successActive: '#64BE79',
    successSecondary: '#E8FBED',
    successSecondaryHighlighted: '#B7ECC4',
    successSecondaryActive: '#8DD7A3',
    successText: '#28513B',

    // Warning
    warning: '#876E2F',
    warningHighlighted: '#C4A650',
    warningActive: '#EDCC74',
    warningSecondary: '#FFF8E7',
    warningSecondaryHighlighted: '#FFF3CD',
    warningSecondaryActive: '#F6E3A7',
    warningText: '#59491D',

    danger: '#7D2521',
    dangerHighlighted: '#A83029',
    dangerActive: '#C94039',
    dangerSecondary: '#F8E8E7',
    dangerSecondaryHighlighted: '#E9ADAB',
    dangerSecondaryActive: '#D36C68',
    dangerText: '#591E1B',

    metric: '#0A857D',
    metricHighlighted: '#06B1A6',
    metricActive: '#07CFC2',
    metricSecondary: '#D3FDF3',
    metricSecondaryHighlighted: '#B5FCEA',
    metricSecondaryActive: '#93FBE7',
    metricText: '#065652',

    dimension: '#0078BD',
    dimensionHighlighted: '#009CF5',
    dimensionActive: '#4FBFFF',
    dimensionSecondary: '#E5F6FF',
    dimensionSecondaryHighlighted: '#C7EBFF',
    dimensionSecondaryActive: '#A9DFFE',
    dimensionText: '#054D76',

    // Text
    text,
    textSecondary: '#6B7280',
    textMuted: '#B2BAC2',
    textInverted: '#F8F9F9',
    textInvertedSecondary: '#98AADD',

    // Background
    background: '#FFFFFF',
    backgroundSecondary: '#F8F9F9',
    backgroundInverted: '#0B1947',
    backgroundInvertedHighlighted: '#FFFFFF66',
    backgroundEmphasis: '#DDE0E4',
    backgroundContent: '#EDEFF1',

    // Border
    border: '#DDE0E4',
    borderInverted: '#FFFFFF40',

    // Shadow
    shadow: setAlpha(text, 0.6).toHex() as HexString,
    // CSS type safe
    transparent: 'transparent',
    current: 'currentcolor',
} satisfies Record<string, Color>

export const colors: { [K in keyof typeof definition]: Color } = definition

export type Colors = typeof colors
export type ColorToken = keyof typeof definition

export const isColorToken = (color?: ResponsiveValue<ColorsAccessPaths | CssGlobals>): color is ColorToken =>
    !!color && typeof color === 'string' && Object.keys(colors).includes(color)
