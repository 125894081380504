import { forwardRef } from 'react'

import type { IconProps, PrivateIconProps } from './shared'
import { iconSizeMap } from './shared'
import { useSlotProps } from '../../utils/components/slots'
import { getFocusRing, useFocusStyle } from '../../utils/focus'
import { isVisualRegressionEnv } from '../../utils/is-visual-regression-env'
import type { Props as BoxProps } from '../box/box'
import { Box } from '../box/box'
import { ConditionalWrapWithTooltip } from '../tooltip-internal/conditional-wrap-with-tooltip'

const getColor = ({
    colorProp,
    variant,
}: {
    colorProp: 'currentcolor' | IconProps['color']
    variant: IconProps['variant']
}): BoxProps['color'] => {
    if (variant === 'danger') {
        return 'dangerActive'
    }

    if (variant) {
        return `${variant}Highlighted` as const
    }

    return colorProp as BoxProps['color']
}

/** @public this is our base to generate icons from */
export const Icon = forwardRef<HTMLElement, IconProps & PrivateIconProps>((props, ref) => {
    const {
        rotate,
        size = 'small',
        css,
        color: colorProp = 'currentcolor',
        variant,
        viewBox = '0 0 24 24',
        tooltip,
        children,
        ...slotProps
    } = useSlotProps(props, 'icon')

    return (
        <ConditionalWrapWithTooltip tooltip={tooltip} {...(isVisualRegressionEnv && { visible: true })}>
            <Box // @ts-expect-error Remove after #1528 is merged.
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox={viewBox}
                ref={ref}
                as="svg"
                color={getColor({ colorProp, variant })}
                overflow="visible"
                flexShrink={0}
                display="inline-block"
                height={iconSizeMap[size]}
                width={iconSizeMap[size]}
                tabIndex={tooltip ? 0 : undefined}
                css={[
                    {
                        boxSizing: 'content-box',
                        '& *[fill]:not([fill="none"])': {
                            fill: 'currentcolor',
                        },
                        '& *[stroke]': {
                            stroke: 'currentcolor',
                        },
                        transform: rotate ? `rotate(${rotate}deg);` : undefined,
                        ...getFocusRing(useFocusStyle(variant)),
                    },
                    css,
                ]}
                {...slotProps}
            >
                {children}
            </Box>
        </ConditionalWrapWithTooltip>
    )
})

Icon.displayName = 'Icon'
