import type { Size } from '../../types'
import { px } from '../../utils/css-unit'
import { useTheme } from '../../utils/use-theme'

const useResolveSizeValue = (size: Size) => {
    const theme = useTheme()

    switch (size) {
        case 'x-small': {
            return px(14)
        }
        default: {
            return theme.space[size]
        }
    }
}

export const useWrappedIconSizing = (size: Size) => {
    const pixels = useResolveSizeValue(size)

    return {
        height: pixels,
        width: pixels,
    }
}
