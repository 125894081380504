import deepMerge from 'deepmerge'

const mergeCss = (css) => {
    if (!css) {
        return {}
    }

    if (Array.isArray(css)) {
        // eslint-disable-next-line unicorn/no-array-reduce
        return css.reduce(
            (mergedCss, currentCss) =>
                deepMerge(mergedCss, Array.isArray(currentCss) ? mergeCss(currentCss) : currentCss || {}),
            {},
        )
    }

    return css
}

export default mergeCss
