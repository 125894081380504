import { type LabelAria } from '@react-aria/label'

import { useFieldContext } from './context'
import { Flex } from '../flex/flex'
import type { InfoTipProp } from '../info-tip/utils'
import { getInfoTipByProps } from '../info-tip/utils'
import { Text } from '../text/text'

type Props = {
    as: 'label' | 'span'
    children: string
    labelProps: LabelAria['labelProps']
    infoTip?: InfoTipProp
}

export const FieldLabel = ({ as, children, infoTip, labelProps }: Props) => {
    const { required } = useFieldContext()
    const elementProps = {
        as,
        ...(as === 'label' ? labelProps : undefined),
    }

    return (
        <Flex alignItems="center" minHeight="medium" css={{ gap: 'xx-small', flexShrink: 0 }}>
            {required && (
                <Text color="dangerActive" marginRight="negative-xx-small">
                    *
                </Text>
            )}
            <Text
                {...elementProps}
                display="inline-block"
                textStyle="body"
                css={{ fontWeight: 'normal', alignItems: 'center' }}
            >
                {children}
            </Text>
            {infoTip && getInfoTipByProps({ props: infoTip })}
        </Flex>
    )
}

FieldLabel.displayName = 'FieldLabel'
