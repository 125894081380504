import type { ReactElement } from 'react'
import { forwardRef } from 'react'

import type { SchemaMapping, Size, Variant } from '../../types'
import { SlotProvider } from '../../utils/components/slots'
import type { Props as BoxProps } from '../box/box'
import { Flex } from '../flex/flex'

const sizeMap = {
    'x-small': '24px',
    small: '32px',
    medium: '48px',
    large: '56px',
    'x-large': '72px',
} as const satisfies Record<Size, string>

type Props = {
    size?: Size
    variant?: Variant | SchemaMapping | 'brand' | 'neutral'
    children: ReactElement
} & Omit<BoxProps, 'children'>

const getColorProps = (variant: NonNullable<Props['variant']>) => {
    switch (variant) {
        case 'neutral': {
            return {
                backgroundColor: 'backgroundEmphasis',
                color: 'textSecondary',
            } as const
        }

        case 'danger': {
            return {
                backgroundColor: 'dangerSecondary',
                color: 'dangerHighlighted',
            } as const
        }

        case 'brand': {
            return {
                backgroundColor: 'brandSecondaryActive',
                color: 'brand',
            } as const
        }

        default: {
            return {
                color: `${variant}`,
                backgroundColor: `${variant}SecondaryHighlighted`,
            } as const
        }
    }
}

export const SpotIcon = forwardRef<HTMLElement, Props>(
    ({ children, size = 'medium', variant = 'brand', ...props }, ref) => {
        const { backgroundColor, color } = getColorProps(variant)

        return (
            <Flex
                width={sizeMap[size]}
                height={sizeMap[size]}
                alignItems="center"
                justifyContent="center"
                borderRadius="round"
                flexShrink={0}
                backgroundColor={backgroundColor}
                color={color}
                {...props}
                ref={ref}
            >
                <SlotProvider
                    slots={{
                        icon: {
                            size,
                        },
                        circularProgress: {
                            backgroundColor: 'transparent',
                            color,
                            size,
                        },
                    }}
                >
                    {children}
                </SlotProvider>
            </Flex>
        )
    },
)

SpotIcon.displayName = 'SpotIcon'
