import type { PropsWithChildren } from 'react'
import { createContext, useContext, useLayoutEffect, useState } from 'react'

import { Portal } from '../portal/portal'

type Props = PropsWithChildren<{
    disablePortal?: boolean
}>

export const DisablePortalContext = createContext<boolean>(false)

/**
 * As recommended by react docs https://reactjs.org/docs/portals.html#event-bubbling-through-portals
 * > ...portal elements are inserted into the DOM tree after the Modal's children are mounted, meaning that children will be mounted on a detached DOM node.
 * > ...to measure a DOM node, or uses 'autoFocus' in a descendant, add state to Modal and only render the children when Modal is inserted in the DOM tree
 *
 * To have `autoFocus`  work, we need to render the dialog after the portal has been inserted into the DOM tree.
 */

export const DialogContainer = ({ children, disablePortal }: Props) => {
    const [show, setShow] = useState(false)
    const disablePortalDefault = useContext(DisablePortalContext)
    const shouldDisablePortal = disablePortal ?? disablePortalDefault

    useLayoutEffect(() => setShow(true), [setShow])

    if (shouldDisablePortal) {
        return <DisablePortalContext.Provider value={false}>{children}</DisablePortalContext.Provider>
    }

    return (
        <DisablePortalContext.Provider value={false}>
            <Portal>{show && children}</Portal>
        </DisablePortalContext.Provider>
    )
}

DialogContainer.displayName = 'DialogContainer'
