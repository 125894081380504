import type { ReactElement, ReactNode, Ref } from 'react'
import { createContext, forwardRef } from 'react'

import type { Orientation } from '../../types'
import { useControlled } from '../../utils/use-controlled'
import { useFieldContext } from '../field/context'
import { Group } from '../group/group'
import type { Props as GroupProps } from '../group/group'

export type ButtonGroupButtonValue = string | number

type CommonProps = {
    controlled?: boolean
    children?: ReactNode
} & Omit<GroupProps, 'selected' | 'onChange' | 'multiple'>

type ButtonGroupSingleSelectionProps<T extends ButtonGroupButtonValue> = {
    multi?: false
    selected?: T | [T] | null // Allow array with single item for now to avoid breaking changes
    onChange?: (opt: T) => void
    setSelected?: (opt: T) => void
} & CommonProps

type ButtonGroupMultiSelectionProps<T extends ButtonGroupButtonValue> = {
    multi: true
    selected?: ReadonlyArray<T>
    onChange?: (opt: ReadonlyArray<T>) => void
    setSelected?: (opt: ReadonlyArray<T>) => void
} & CommonProps

export type ButtonGroupProps<T extends ButtonGroupButtonValue> =
    | ButtonGroupSingleSelectionProps<T>
    | ButtonGroupMultiSelectionProps<T>

type ButtonGroupContextProps = {
    onClick?: (value?: ButtonGroupButtonValue) => void
    selected?: ReadonlyArray<ButtonGroupButtonValue> | ButtonGroupButtonValue | [ButtonGroupButtonValue] | null
    orientation: Orientation
    isInButtonGroup: boolean
}

export const ButtonGroupContext = createContext<ButtonGroupContextProps>({
    selected: undefined,
    orientation: 'horizontal',
    onClick: undefined,
    isInButtonGroup: false,
})

const ButtonGroupComponent = <T extends ButtonGroupButtonValue>(
    {
        children,
        controlled,
        multi,
        selected,
        onChange,
        setSelected,
        orientation = 'horizontal',
        css,
        ...props
    }: ButtonGroupProps<T>,
    ref: Ref<HTMLElement>,
) => {
    const [singleValue, setSingleValue] = useControlled(
        !multi && controlled ? selected : undefined,
        multi ? undefined : selected,
        'buttonGroupSingle',
    )

    const [multiValue, setMultiValue] = useControlled(
        multi && controlled ? selected : undefined,
        multi ? selected : [],
        'buttonGroupMulti',
    )

    const field = useFieldContext()

    return (
        <ButtonGroupContext.Provider
            /* eslint-disable-next-line @eslint-react/no-unstable-context-value */
            value={{
                selected: multi ? multiValue : singleValue,
                isInButtonGroup: true,
                orientation,
                onClick: (currentValue) => {
                    const assertedValue = currentValue as T // We don't want to make the context provider generic because that would mean instantiating a new one for each ButtonGroup, so assert the generic type here.

                    if (multi) {
                        const updatedValue = multiValue?.includes(assertedValue)
                            ? multiValue.filter((val) => val !== currentValue)
                            : [...(multiValue ?? []), assertedValue]

                        setMultiValue(updatedValue)
                        onChange?.(updatedValue)
                        setSelected?.(updatedValue)
                    } else {
                        setSingleValue(assertedValue)
                        onChange?.(assertedValue)
                        setSelected?.(assertedValue)
                    }
                },
            }}
        >
            <Group ref={ref} orientation={orientation} css={[css]} {...props} {...field.fieldProps}>
                {children}
            </Group>
        </ButtonGroupContext.Provider>
    )
}

export const ButtonGroup = forwardRef(ButtonGroupComponent) as (<T extends ButtonGroupButtonValue>(
    props: ButtonGroupProps<T>,
) => ReactElement) & { displayName: string }
ButtonGroup.displayName = 'ButtonGroup'
