import type { ReactNode } from 'react'

import type { FieldContext } from './context'
import { isComponentInstance } from '../../utils/component-type-guards'
import { Checkbox } from '../checkbox/checkbox'
import { Switch } from '../switch/switch'

export function isLabelLessComponent(component: ReactNode) {
    /**
     * Checkbox and Switch components come with their label. We need to maintain this list to include all form control
     * components that come with their labels until we decide how we want fields with multiple labels to behave.
     */
    return isComponentInstance(component, Checkbox) || isComponentInstance(component, Switch)
}

export const getFieldWrappedComponentWidth = (fieldContext: FieldContext) => {
    if ('fieldProps' in fieldContext) {
        return fieldContext.fullWidth ? '100%' : 'fit-content'
    }

    return 'auto'
}
