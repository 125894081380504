import type { ComponentProps, InputHTMLAttributes } from 'react'
import { forwardRef, useCallback, useContext, useRef } from 'react'

import type { LimitedSize, Ref, SchemaMapping, Variant } from '../../types'
import { assertNever } from '../../utils/assert-never'
import * as constants from '../../utils/constants'
import { useFocusStyle } from '../../utils/focus'
import * as sizeMapping from '../../utils/size-mapping'
import { useComposeRefs } from '../../utils/use-compose-refs'
import { useTheme } from '../../utils/use-theme'
import type { Props as BoxProps } from '../box/box'
import { Box } from '../box/box'
import { sizeToFontSizeMap } from '../button/size-mapping'
import { useFieldContext } from '../field/context'
import { Flex } from '../flex/flex'
import { GroupContext } from '../group/group-context'
import { Stack } from '../stack/stack'

type Mode = 'singleline' | 'multiline'

export type InnerInputProps = {
    mode?: Mode
    size?: LimitedSize
    type?:
        | 'button'
        | 'checkbox'
        | 'color'
        | 'date'
        | 'datetime-local'
        | 'email'
        | 'file'
        | 'hidden'
        | 'image'
        | 'month'
        | 'number'
        | 'password'
        | 'radio'
        | 'range'
        | 'reset'
        | 'search'
        | 'submit'
        | 'tel'
        | 'text'
        | 'time'
        | 'url'
        | 'week'
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'color' | 'css' | 'prefix' | 'size' | 'width' | 'height'>

const InnerInput = forwardRef<HTMLElement, InnerInputProps>(({ size = constants.sizes.MEDIUM, ...props }, ref) => {
    const theme = useTheme()
    const { size: actualSize = size, isInGroup: ignored, ...groupProps } = useContext(GroupContext)
    const isMultiLine = props.mode === 'multiline'

    return (
        <Box
            ref={ref}
            as="input"
            color={props.disabled ? 'textMuted' : 'text'}
            {...(isMultiLine
                ? {
                      flexGrow: 1,
                      flexBasis: 100,
                      minWidth: 100,
                  }
                : {
                      minWidth: 0,
                      width: '100%',
                  })}
            // override normalize.css styles for input cursor
            style={{ cursor: 'inherit' }}
            css={{
                // override Safari default behaviour for disabled input
                ...(props.disabled &&
                    props.value && {
                        WebkitTextFillColor: theme.colors.textMuted,
                    }),
                backgroundColor: 'transparent',
                border: 'none',
                fontSize: sizeToFontSizeMap[actualSize],
                flex: 1,
                height: 'inherit',
                lineHeight: sizeToFontSizeMap[actualSize],
                textOverflow: 'ellipsis',
                '::placeholder': {
                    color: 'textMuted',
                },
                // Focus should start on inner input then move to prefix and suffix elements.
                // DOM order reflects focus path.
                // Flexbox is used for visual order as prefix, inner input and suffix
                // Because of above we cannot use Stack in this case.
                order: Number.MAX_SAFE_INTEGER,
            }}
            data-testid="inner-input"
            {...groupProps}
            {...props}
        />
    )
})

InnerInput.displayName = 'InnerInput'

type PrefixParams = { innerInput: JSX.Element }
type PrefixFunction = (prefixParams: PrefixParams) => JSX.Element
export type Props = {
    inputRef?: Ref<HTMLInputElement>
    variant?: Variant | SchemaMapping
    prefix?: JSX.Element | Array<JSX.Element> | PrefixFunction
    suffix?: JSX.Element
    mode?: Mode
} & Pick<BoxProps, 'css' | 'width' | 'minWidth' | 'maxWidth'> &
    InnerInputProps

const resolveInputHeight = (size: LimitedSize) => {
    switch (size) {
        case 'small': {
            return 'medium'
        }
        case 'medium': {
            return 'large'
        }
        case 'large': {
            return 'x-large'
        }
        default: {
            assertNever(size)

            return 'large'
        }
    }
}

export const Input = forwardRef<HTMLDivElement, Props>(
    // eslint-disable-next-line complexity
    (
        {
            inputRef,
            prefix,
            suffix,
            css,
            size = constants.sizes.MEDIUM,
            type = 'text',
            onClick,
            width = '100%',
            minWidth,
            maxWidth,
            mode = 'singleline',
            ...props
        },
        ref,
    ) => {
        const { size: actualSize = size, className: groupClassName } = useContext(GroupContext)
        const field = useFieldContext()

        const {
            disabled = field.disabled ?? false,
            readOnly = field.readOnly ?? false,
            variant = field.variant,
            required = field.required,
            ...rest
        } = props

        const palette = variant ?? 'brand'
        const focusStyle = useFocusStyle(palette)
        const isMultiLine = mode === 'multiline'
        const paddingProps: ComponentProps<typeof Flex> = {
            paddingLeft: prefix ? 'xx-small' : 'x-small',
            paddingRight: suffix ? 'xx-small' : 'x-small',
            // @ts-expect-error we need 2px here.
            paddingY: isMultiLine ? '2px' : 0,
        }
        const innerInputRef = useRef<HTMLInputElement>(null)
        const composedInputRef = useComposeRefs(innerInputRef, inputRef)

        const focusInnerInput = useCallback(() => {
            innerInputRef.current?.focus()
        }, [innerInputRef])

        const inputHeight = resolveInputHeight(actualSize)
        const theme = useTheme()

        const innerInput = (
            <InnerInput
                ref={composedInputRef}
                type={type}
                size={size}
                disabled={disabled}
                readOnly={readOnly}
                required={required}
                {...field.fieldProps}
                {...rest}
                mode={mode}
            />
        )

        return (
            <Flex
                ref={ref}
                backgroundColor={disabled ? 'backgroundSecondary' : 'background'}
                borderColor={variant ? `${variant}Active` : 'border'}
                borderRadius="medium"
                borderStyle="solid"
                borderWidth="thin"
                alignItems="center"
                onClick={onClick}
                onClickCapture={focusInnerInput}
                width={width}
                minWidth={minWidth}
                maxWidth={maxWidth}
                className={groupClassName}
                {...(isMultiLine
                    ? {
                          minHeight: inputHeight,
                      }
                    : {
                          height: inputHeight,
                      })}
                css={[
                    {
                        '> *:not(:last-child)': {
                            marginRight: 'xx-small',
                        },
                        // Overriding these styles on the inner input prevents the style interference with global styles.
                        'input, input:focus': {
                            border: 'none !important',
                            boxShadow: 'none !important',
                            outline: 'none !important',
                        },
                        'input::-moz-color-swatch': {
                            border: 'none',
                            display: 'none',
                        },
                        'input::-webkit-color-swatch': {
                            border: 'none',
                            display: 'none',
                        },
                        ...(disabled
                            ? {
                                  borderColor: 'border',
                                  cursor: 'not-allowed',
                                  color: 'textMuted',
                              }
                            : {
                                  cursor: readOnly ? 'default' : 'auto',
                                  '&:active': {
                                      borderColor: `${palette}`,
                                  },
                                  '&:hover': {
                                      borderColor: `${palette}Highlighted`,
                                  },
                                  ':focus-within': focusStyle,
                              }),
                    },
                    css,
                ]}
                {...paddingProps}
            >
                {typeof prefix === 'function' ? (
                    prefix({ innerInput })
                ) : (
                    <Stack
                        overflow="hidden"
                        wrap={isMultiLine}
                        height={isMultiLine ? undefined : theme.space[inputHeight]}
                        spacing="xx-small"
                        orientation="horizontal"
                        flexGrow={1}
                        alignItems={isMultiLine ? undefined : 'center'}
                        css={{
                            lineHeight: sizeMapping.fontSize[actualSize] as string,
                        }}
                    >
                        {innerInput}
                        {prefix}
                    </Stack>
                )}
                {suffix && (
                    <Flex alignItems="center" flexShrink={0}>
                        {suffix}
                    </Flex>
                )}
            </Flex>
        )
    },
)

Input.displayName = 'Input'
