import type { ReactNode } from 'react'

import { flushSync } from 'react-dom'
import { createRoot } from 'react-dom/client'

export const renderToString = (tree: ReactNode) => {
    const div = document.createElement('div')
    const root = createRoot(div)

    flushSync(() => {
        root.render(tree)
    })

    const html = div.innerHTML

    root.unmount()

    return html
}

export const renderToStringAsync = (tree: ReactNode) =>
    new Promise<string>((resolve) => {
        queueMicrotask(() => {
            resolve(renderToString(tree))
        })
    })
