import type { ReactNode } from 'react'
import { createContext } from 'react'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type StoryContextType = Record<string, any>

type Props = {
    storyContext: StoryContextType
    children: ReactNode
}

export const StoryContext = createContext<StoryContextType>({})

/** @public used in storybook */
export const StoryContextProvider = ({ storyContext, children }: Props) => (
    <StoryContext.Provider value={storyContext}>{children}</StoryContext.Provider>
)
