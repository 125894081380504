import type { TLengthStyledSystem } from 'styled-system'

export type CssGlobals = '-moz-initial' | 'inherit' | 'initial' | 'revert' | 'revert-layer' | 'unset'
export type PixelValue = `${number}px`

export function px(value: number): PixelValue
export function px(value: TLengthStyledSystem): string
export function px(value: TLengthStyledSystem) {
    return Number.isNaN(Number(value)) ? (value as string) : `${value}px`
}

export const addCSSUnit = (value: TLengthStyledSystem, scale: Array<TLengthStyledSystem> = []) =>
    px(scale[value as number] ?? value)

export const removePxUnit = (value: string) => Number(value.slice(0, -2))
export const removeTimeUnit = (value: string) => Number(value.slice(1, -1))
