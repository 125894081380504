/* eslint id-length: ["error", { "exceptions": ["x", "y"] }] */

import type { HTMLProps, ReactElement, Ref } from 'react'
import { useId, useMemo, useState } from 'react'

import { autoUpdate, useDismiss, useFloating, useFocus, useHover, useInteractions, useRole } from '@floating-ui/react'

import { TooltipInternalContent } from './tooltip-internal-content'
import { useTooltipInternalProvider } from './tooltip-internal-provider'
import { TooltipInternalTrigger } from './tooltip-internal-trigger'
import { usePopoverDefaultMiddleware } from '../popover/popover-middleware'

type Props = {
    children: ReactElement<HTMLProps<Element>> & { ref?: Ref<HTMLElement> }
    title: JSX.Element | string
    visible?: boolean
    onVisibleChange?: (visible: boolean) => void
}

const useTooltip = ({
    visible: controlledOpen,
    onVisibleChange: setControlledOpen,
}: Pick<Props, 'visible' | 'onVisibleChange'>) => {
    const id = useId()
    const [hiding, setHiding] = useState(false)
    const [uncontrolledOpen, setUncontrolledOpen] = useState<boolean>(false)

    const isOpen = controlledOpen ?? uncontrolledOpen
    const setIsOpen = setControlledOpen ?? setUncontrolledOpen

    // Close all other tooltips via TooltipProvider when one is open
    const toggleOtherTooltips = useTooltipInternalProvider({
        id,
        setVisible: setIsOpen,
    })

    const data = useFloating({
        placement: 'top',
        open: isOpen,
        onOpenChange: (isVisible: boolean) => {
            if (isVisible) {
                toggleOtherTooltips()
            }

            setIsOpen(isVisible)
            setHiding(!true)
        },
        whileElementsMounted: autoUpdate,
        middleware: usePopoverDefaultMiddleware(),
    })

    const context = data.context

    const hover = useHover(context, {})
    const focus = useFocus(context, {})
    const dismiss = useDismiss(context)
    const role = useRole(context, { role: 'tooltip' })

    const interactions = useInteractions([hover, focus, dismiss, role])

    return useMemo(
        () => ({
            id,
            isOpen,
            setIsOpen,
            hiding,
            ...interactions,
            ...data,
        }),
        [id, isOpen, setIsOpen, hiding, interactions, data],
    )
}

export const TooltipInternal = ({ children, title, visible, onVisibleChange }: Props) => {
    const { refs, id, getReferenceProps, getFloatingProps, isOpen, setIsOpen, hiding, strategy, x, y } = useTooltip({
        visible,
        onVisibleChange,
    })

    return (
        <>
            <TooltipInternalTrigger
                id={id}
                setIsOpen={setIsOpen}
                setReference={refs.setReference}
                getReferenceProps={getReferenceProps}
            >
                {children}
            </TooltipInternalTrigger>
            <TooltipInternalContent
                id={id}
                setFloating={refs.setFloating}
                getFloatingProps={getFloatingProps}
                isOpen={isOpen}
                hiding={hiding}
                strategy={strategy}
                x={x}
                y={y}
            >
                {title}
            </TooltipInternalContent>
        </>
    )
}
