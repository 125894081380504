import { grid } from './grid'
import type { PixelValue } from '../../utils/css-unit'
import { px } from '../../utils/css-unit'

const definition = {
    none: px(0),
    'xx-small': px(grid * 0.5),
    'x-small': px(grid),
    small: px(grid * 2),
    medium: px(grid * 3),
    large: px(grid * 4),
    'x-large': px(grid * 5),
    'xx-large': px(grid * 6),
    'negative-xx-small': px(grid * -0.5),
    'negative-x-small': px(grid * -1),
    'negative-small': px(grid * -2),
    'negative-medium': px(grid * -3),
    'negative-large': px(grid * -4),
    'negative-x-large': px(grid * -5),
    'negative-xx-large': px(grid * -6),
} satisfies Record<string, PixelValue>

export const space: { [K in keyof typeof definition]: PixelValue } = definition
