import type { AxiosError } from 'axios'
import axios from 'axios'
import Cookies from 'js-cookie'

const csrftoken = Cookies.get('csrftoken')
const INVALID_PAGE_REGEX = /invalid page/i

export type ApiError<T = unknown> = AxiosError<T>

export const isApiError = <T = unknown>(error: unknown): error is ApiError<T> => !!error && axios.isAxiosError(error)

export const isInvalidPageError = (error: unknown) =>
    isApiError<{ detail: string }>(error) &&
    error.response?.status === 404 &&
    INVALID_PAGE_REGEX.test(error.response.data.detail)

/** @public we have tests for this */
export const paramsSerializer = (params: Record<string, string | Array<string>>) => {
    if ('queryString' in params && typeof params.queryString === 'string') {
        return params.queryString
    }

    const paramsNormalized = Object.entries(params).flatMap(([key, value]) => {
        if (Array.isArray(value)) {
            return value.map((singleValue) => [key, singleValue])
        }

        return [[key, value]]
    })

    return new URLSearchParams(paramsNormalized).toString()
}

export const retryWhenServerError = (failureCount: number, error: unknown): boolean =>
    failureCount < 3 && (!isApiError(error) || !error.response?.status || error.response.status >= 500)

/**
 * The only used API approach in the app.
 */
export default axios.create({
    withCredentials: true,
    headers: csrftoken ? { 'X-CSRFToken': csrftoken } : undefined,
    paramsSerializer,
})
