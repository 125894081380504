import type { ComponentProps, ReactNode } from 'react'

import { keyframes } from '@emotion/core'
import type { Keyframes } from '@emotion/core'
import { omit } from 'remeda'

import { DrawerInternal } from './drawer-internal'
import type { Props as DrawerInternalProps } from './drawer-internal'
import { Button } from '../button/button'
import { ButtonSecondary } from '../button/button-secondary'
import { DialogActions } from '../dialog/dialog-actions'

type Props = Omit<DrawerInternalProps, 'footer'> & {
    contain?: boolean
    footer?: {
        additionalActions?: ReactNode
        cancelAction?: (Omit<ComponentProps<typeof Button>, 'children' | 'label'> & { label?: ReactNode }) | null
        applyAction?: (Omit<ComponentProps<typeof Button>, 'children' | 'label'> & { label?: ReactNode }) | null
    }
}

const slideRight = keyframes({
    from: {
        transform: 'translateX(100%)',
    },
    to: {
        transform: '0',
    },
})

const slideBottom = keyframes({
    from: {
        transform: 'translateY(100%)',
    },
    to: {
        transform: '0',
    },
})

const slideLeft = keyframes({
    from: {
        transform: 'translateX(-100%)',
    },
    to: {
        transform: '0',
    },
})

const PLACEMENT_VIEW_TRANSITION_MAPPING: Record<Props['placement'], { name: string; keyframes: Keyframes }> = {
    left: {
        name: 'drawer-left',
        keyframes: slideLeft,
    },
    right: {
        name: 'drawer-right',
        keyframes: slideRight,
    },
    bottom: {
        name: 'drawer-bottom',
        keyframes: slideBottom,
    },
} as const

export const createViewTransitionCss = (placement: keyof typeof PLACEMENT_VIEW_TRANSITION_MAPPING) => {
    const viewTransitionName = PLACEMENT_VIEW_TRANSITION_MAPPING[placement].name
    const animationName = PLACEMENT_VIEW_TRANSITION_MAPPING[placement].keyframes

    return {
        /* Enter */
        [`::view-transition-new(${viewTransitionName}):only-child, ::view-transition-old(${viewTransitionName}):only-child`]:
            {
                animationName,
                animationDuration: '0.2s',
            },

        /* Leave */
        [`::view-transition-old(${viewTransitionName}):only-child`]: {
            animationDirection: 'reverse',
        },
    }
}

export const Drawer = ({ children, footer, onClose, ...props }: Props) => (
    <DrawerInternal
        onClose={onClose}
        {...props}
        css={{
            viewTransitionName: PLACEMENT_VIEW_TRANSITION_MAPPING[props.placement].name,
        }}
        footer={
            footer ? (
                <DialogActions additionalActions={footer.additionalActions}>
                    {footer.cancelAction && (
                        <ButtonSecondary onClick={onClose} {...omit(footer.cancelAction, ['label'])}>
                            {footer.cancelAction.label ?? 'Cancel'}
                        </ButtonSecondary>
                    )}
                    {footer.applyAction && (
                        <Button type="submit" {...omit(footer.applyAction, ['label'])}>
                            {footer.applyAction.label ?? 'Apply'}
                        </Button>
                    )}
                </DialogActions>
            ) : undefined
        }
    >
        {children}
    </DrawerInternal>
)

Drawer.displayName = 'Drawer'
