import type { ReactNode } from 'react'
import { useEffect, useRef, useState } from 'react'

import { ModalDialog } from './modal-dialog'
import { Field } from '../field/field'
import { IconAlertCircle } from '../icons/generated/alert-circle'
import { Input } from '../input/input'
import { Stack } from '../stack/stack'
import { Text } from '../text/text'

export type DeleteDialogProps = {
    subject: string
    subjectName: string
    additionalPhrase?: ReactNode
    mustConfirmSubjectName?: boolean
    onClose: () => void
    onConfirm?: () => void
    disablePortal?: boolean
}

export const DeleteDialog = ({
    subject,
    subjectName,
    additionalPhrase,
    mustConfirmSubjectName = false,
    onClose,
    onConfirm,
    disablePortal,
}: DeleteDialogProps) => {
    const [state, setState] = useState<string>('')
    const applyRef = useRef<HTMLButtonElement>(null)
    const disabled = mustConfirmSubjectName && state !== subjectName

    useEffect(() => {
        if (!disabled) {
            applyRef.current?.focus()
        }
    })

    return (
        <ModalDialog
            title={`Delete ${subject}`}
            disablePortal={disablePortal}
            onClose={onClose}
            cancelAction={{ label: 'Cancel', onClick: onClose }}
            applyAction={{
                label: `Delete ${subject}`,
                onClick: onConfirm,
                ref: applyRef,
                variant: 'danger',
                disabled,
            }}
            size="small"
        >
            <Stack spacing="small">
                <Stack spacing="x-small" alignItems="center" orientation="horizontal">
                    <IconAlertCircle variant="danger" />
                    <Text wordBreak="break-word">
                        This will permanently delete{' '}
                        <Text wordBreak="break-all" fontWeight="bold">
                            {subjectName}
                        </Text>
                        .{additionalPhrase ? <Text as="p">{additionalPhrase}</Text> : undefined}
                    </Text>
                </Stack>
                {mustConfirmSubjectName && (
                    <Field label={`Type in the ${subject} name to confirm`}>
                        <Input
                            onChange={(event) => {
                                const value = event.target.value

                                setState(value)
                            }}
                            value={state}
                        />
                    </Field>
                )}
            </Stack>
        </ModalDialog>
    )
}

DeleteDialog.displayName = 'DeleteDialog'
