import type { ComponentPropsWithRef, ElementType, ReactNode } from 'react'
import { Children, forwardRef, useMemo } from 'react'

import { Flex } from '../flex/flex'
import { Stack } from '../stack/stack'
import { StartEnd } from '../start-end/start-end'

type PropsOf<T extends ElementType> = ComponentPropsWithRef<T>

type Props = {
    additionalActions?: ReactNode
    children: ReactNode
} & PropsOf<typeof Flex>

export const DialogActions = forwardRef<HTMLElement, Props>(({ additionalActions, children, ...props }, ref) => {
    // eslint-disable-next-line @eslint-react/no-children-to-array
    const reversedChildren = useMemo(() => [...Children.toArray(children)].reverse(), [children])

    return (
        <StartEnd ref={ref} flexDirection="row-reverse" width="100%" {...props}>
            <Flex flexDirection="row-reverse" minWidth={0} css={{ '> *:not(:last-child)': { marginLeft: 'x-small' } }}>
                {reversedChildren}
            </Flex>

            <Stack orientation="horizontal" spacing="x-small" minWidth={0}>
                {additionalActions}
            </Stack>
        </StartEnd>
    )
})

DialogActions.displayName = 'DialogActions'
