import { ModalDialog } from './modal-dialog'
import { IconAlertCircle } from '../icons/generated/alert-circle'
import { Stack } from '../stack/stack'
import { Text } from '../text/text'

export type ConfirmDialogProps = {
    title?: string
    message: string
    cancelLabel?: string
    applyLabel?: string
    onClose: () => void
    disablePortal?: boolean
    onConfirm?: () => void
}

export const ConfirmDialog = ({
    title = 'Confirm changes?',
    message,
    cancelLabel = 'Cancel',
    applyLabel = 'Apply',
    disablePortal,
    onClose,
    onConfirm,
}: ConfirmDialogProps) => (
    <ModalDialog
        title={title}
        disablePortal={disablePortal}
        cancelAction={{ label: cancelLabel, onClick: onClose }}
        applyAction={{ label: applyLabel, onClick: onConfirm, autoFocus: true }}
        onClose={onClose}
        size="small"
    >
        <Stack spacing="x-small" alignItems="center" orientation="horizontal">
            <IconAlertCircle color="brand" />

            <Text>{message}</Text>
        </Stack>
    </ModalDialog>
)

ConfirmDialog.displayName = 'ConfirmDialog'
