import type { ReactElement } from 'react'

import { TooltipInternal } from './tooltip-internal'

export const ConditionalWrapWithTooltip = ({
    tooltip,
    children,
    visible,
}: {
    visible?: boolean
    tooltip?: string
    children: ReactElement
}) => {
    if (!tooltip) {
        return children
    }

    return (
        <TooltipInternal title={tooltip} visible={visible}>
            {children}
        </TooltipInternal>
    )
}
