import { system } from 'styled-system'

import type { SideBordersAccessPaths } from '../../theme/tokens/generated/token-access-paths'
import type { Config, ResponsiveValue } from '../styled-system-re-exported-types'

export type SideBorderProps = {
    borderTop?: ResponsiveValue<SideBordersAccessPaths>
    borderRight?: ResponsiveValue<SideBordersAccessPaths>
    borderBottom?: ResponsiveValue<SideBordersAccessPaths>
    borderLeft?: ResponsiveValue<SideBordersAccessPaths>
}

/** @public used in docs */
export const sideBordersConfig = {
    borderTop: {
        property: 'borderTop',
        scale: 'sideBorders',
    },
    borderRight: {
        property: 'borderRight',
        scale: 'sideBorders',
    },
    borderBottom: {
        property: 'borderBottom',
        scale: 'sideBorders',
    },
    borderLeft: {
        property: 'borderLeft',
        scale: 'sideBorders',
    },
} as const satisfies Config<keyof SideBorderProps>

export const sideBorders = system(sideBordersConfig)
