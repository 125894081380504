import { system } from 'styled-system'

import type { SpaceAccessPaths } from '../../theme/tokens/generated/token-access-paths'
import type { CssGlobals, PixelValue } from '../../utils/css-unit'
import { widthTransformer } from '../prop-transformers'
import type { Config, ResponsiveValue } from '../styled-system-re-exported-types'
import type { AlignItems, JustifyItems } from './flex'

type AutoCompleteString = string & {}

export type VerticalAlign =
    | CssGlobals
    | 'baseline'
    | 'bottom'
    | 'middle'
    | 'sub'
    | 'super'
    | 'text-bottom'
    | 'text-top'
    | 'top'

type FlexBasis = CssGlobals | 'auto' | 'fit-content' | 'max-content' | 'min-content' | 'content' | number | PixelValue
type Overflow = CssGlobals | 'auto' | 'clip' | 'hidden' | 'scroll' | 'visible'
type Sizing =
    | FlexBasis
    | 'intrinsic'
    | SpaceAccessPaths
    | `${number}ch`
    | `${number}fr`
    | `${number}%`
    | `calc(${string})`
    | `minmax(${string})`
type HeightSizing = Sizing | `${number}vh`
type WidthSizing = Sizing | `${number}vw`
type Display =
    | CssGlobals
    | 'none'
    | 'contents'
    | 'block'
    | 'inline'
    | 'flex'
    | 'flow'
    | 'flow-root'
    | 'grid'
    | 'table'
    | 'table-caption'
    | 'table-cell'
    | 'table-column'
    | 'table-column-group'
    | 'table-footer-group'
    | 'table-header-group'
    | 'table-row'
    | 'table-row-group'
    | 'inline-block'
    | 'inline-flex'
    | 'inline-grid'
    | 'inline-list-item'
    | 'inline-table'
    | 'list-item'

type TrackBreadth = 'auto' | 'max-content' | 'min-content'
type GridAutoDimension = CssGlobals | TrackBreadth | AutoCompleteString
type GridTemplateDimension = CssGlobals | TrackBreadth | 'none' | 'subgrid' | AutoCompleteString
type GridDimension = CssGlobals | 'auto' | number | AutoCompleteString
type GridGap = CssGlobals | SpaceAccessPaths

export type LayoutProps = {
    width?: ResponsiveValue<WidthSizing>
    height?: ResponsiveValue<HeightSizing>
    minWidth?: ResponsiveValue<WidthSizing>
    minHeight?: ResponsiveValue<HeightSizing>
    maxWidth?: ResponsiveValue<WidthSizing>
    maxHeight?: ResponsiveValue<HeightSizing>
    overflow?: ResponsiveValue<Overflow>
    display?: ResponsiveValue<Display>
    verticalAlign?: ResponsiveValue<VerticalAlign>
    flex?: ResponsiveValue<CssGlobals | number | `${number} ${number} ${number | PixelValue | 'auto'}`>
    flexGrow?: ResponsiveValue<CssGlobals | number>
    flexShrink?: ResponsiveValue<CssGlobals | number>
    flexBasis?: ResponsiveValue<FlexBasis>
    justifySelf?: ResponsiveValue<JustifyItems | 'auto'>
    alignSelf?: ResponsiveValue<AlignItems | 'auto'>
    order?: ResponsiveValue<CssGlobals | number>

    gridGap?: ResponsiveValue<GridGap>
    gridRowGap?: ResponsiveValue<GridGap>
    gridColumnGap?: ResponsiveValue<GridGap>
    gridColumn?: ResponsiveValue<GridDimension>
    gridRow?: ResponsiveValue<GridDimension>
    gridArea?: ResponsiveValue<CssGlobals | 'auto' | AutoCompleteString>
    gridAutoFlow?: ResponsiveValue<CssGlobals | 'column' | 'dense' | 'row'>
    gridAutoRows?: ResponsiveValue<GridAutoDimension>
    gridAutoColumns?: ResponsiveValue<GridAutoDimension>
    gridTemplateRows?: ResponsiveValue<GridTemplateDimension>
    gridTemplateColumns?: ResponsiveValue<GridTemplateDimension>
    gridTemplateAreas?: ResponsiveValue<CssGlobals | 'none' | AutoCompleteString>
}

/** @public used in docs */
export const layoutConfig = {
    width: {
        property: 'width',
        scale: 'space',
        transform: widthTransformer,
    },
    height: {
        property: 'height',
        scale: 'space',
    },
    minWidth: {
        property: 'minWidth',
        scale: 'space',
    },
    minHeight: {
        property: 'minHeight',
        scale: 'space',
    },
    maxWidth: {
        property: 'maxWidth',
        scale: 'space',
    },
    maxHeight: {
        property: 'maxHeight',
        scale: 'space',
    },
    overflow: true,
    display: true,
    verticalAlign: true,

    // flexitem
    flex: true,
    flexGrow: true,
    flexShrink: true,
    flexBasis: true,
    justifySelf: true,
    alignSelf: true,
    order: true,

    gridGap: {
        property: 'gridGap',
        scale: 'space',
    },
    gridRowGap: {
        property: 'gridRowGap',
        scale: 'space',
    },
    gridColumnGap: {
        property: 'gridColumnGap',
        scale: 'space',
    },
    gridColumn: true,
    gridRow: true,
    gridArea: true,
    gridAutoFlow: true,
    gridAutoRows: true,
    gridAutoColumns: true,
    gridTemplateRows: true,
    gridTemplateColumns: true,
    gridTemplateAreas: true,
} as const satisfies Config<keyof LayoutProps>

export const layout = system(layoutConfig)
