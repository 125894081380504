import { getPathTo as generatedPathTo } from './generated/get-path-to'

export const getPathTo = {
    ...generatedPathTo,
    datastreamsWizard: ({ authorizationId, contentId }: { authorizationId?: number; contentId?: number }) => {
        const wizardUrl = '/core/apidatastream/add/'
        const params = new URLSearchParams()

        if (authorizationId) {
            params.append('auth', authorizationId.toString())
        }

        if (contentId) {
            params.append('ct_id', contentId.toString())
        }

        const stringifiedParams = params.toString()

        return stringifiedParams ? `${wizardUrl}?${stringifiedParams}` : wizardUrl
    },
    destinationWizard: (options?: { authorizationId: number }) => {
        const wizardUrl = '/target/basetarget/add/'
        const params = new URLSearchParams()

        if (options?.authorizationId) {
            params.append('auth', options.authorizationId.toString())
        }

        const stringifiedParams = params.toString()

        return stringifiedParams ? `${wizardUrl}?${stringifiedParams}` : wizardUrl
    },
    enrichExport: (
        params: Partial<{
            polymorphic_ctype: string
            ordering: string
            workspace: string
            q: string
        }> &
            ({ exclude_entity_ids: string } | { entity_ids: string }),
    ) => {
        const modifiedParams = new URLSearchParams(params).toString()

        return `/api/web-app/enrichments/actions/export/?${modifiedParams}`
    },
    /**
     * Atrium paths
     */
    authorizationDetails: ({ authorizationId, stackUuid }: { authorizationId: number; stackUuid: string }) =>
        `/workspaces/${stackUuid}/authorizations/${authorizationId}/`,
    authorizationsList: ({ stackUuid }: { stackUuid: string }) => `/workspaces/${stackUuid}/authorizations/`,
    cloneWorkspace: ({ workspaceUuid }: { workspaceUuid: string }) => `/workspaces/${workspaceUuid}/clone/`,
    destinations: ({ stackUuid }: { stackUuid: string }) => `/workspaces/${stackUuid}/destinations/`,
    enrichmentsList: ({ stackUuid }: { stackUuid: string }) => `/workspaces/${stackUuid}/enrichments/`,
    enrichmentsAdd: ({ stackUuid, datastreamId }: { stackUuid: string; datastreamId?: number }) => {
        const baseUrl = `/workspaces/${stackUuid}/enrichments/add/`
        const params = new URLSearchParams()

        if (datastreamId) {
            params.append('datastreamId', datastreamId.toString())
        }

        const stringifiedParams = params.toString()

        return stringifiedParams ? `${baseUrl}?${stringifiedParams}` : baseUrl
    },
    enrichmentsAddNew: ({ stackUuid, datastreamId }: { stackUuid: string; datastreamId?: number }) => {
        const baseUrl = `/workspaces/${stackUuid}/enrichments/`
        const params = new URLSearchParams()

        params.append('create', 'new')

        if (datastreamId) {
            params.append('datastreamId', datastreamId.toString())
        }

        const stringifiedParams = params.toString()

        return `${baseUrl}?${stringifiedParams}`
    },
    enrichmentDetails: ({
        enrichmentId,
        stackUuid,
        datastreamId,
        extractId,
    }: {
        enrichmentId: number
        stackUuid: string
        datastreamId?: number
        extractId?: number
    }) => {
        const baseUrl = `/workspaces/${stackUuid}/enrichments/details/${enrichmentId}/`
        const params = new URLSearchParams()

        if (datastreamId) {
            params.append('datastreamId', datastreamId.toString())
        }

        if (extractId) {
            params.append('extractId', extractId.toString())
        }

        const stringifiedParams = params.toString()

        return stringifiedParams ? `${baseUrl}?${stringifiedParams}` : baseUrl
    },
    help: ({ stackUuid }: { stackUuid: string }) => `/workspaces/${stackUuid}/help/`,
    destinationCredentials: ({
        stackUuid,
        destinationUuid,
        biToolName,
    }: {
        stackUuid: string
        destinationUuid: string
        biToolName: string
    }) => `/workspaces/${stackUuid}/destinations/${destinationUuid}/credentials/?biToolName=${biToolName}`,
    onboarding: ({ stackUuid }: { stackUuid: string }) => `/workspaces/${stackUuid}/onboarding/`,
    systemManagedAuthorizationsList: ({ stackUuid }: { stackUuid: string }) =>
        `/workspaces/${stackUuid}/authorizations/system-managed-list/`,
}
