import { isNumber } from 'remeda'
import type { Scale, TLengthStyledSystem } from 'styled-system'
import { get } from 'styled-system'

import { warning } from '../utils/warning'

export const marginTransformer = (value: TLengthStyledSystem, scale?: Scale) => {
    if (isNumber(value)) {
        warning(
            true,
            `Warning: You provided a numeric value (${value}), but the margin transformer resolves only token values based on the spacing scale. If you need to provide a custom value, please provide the CSS unit as well (e.g. "${value}px".`,
        )

        return undefined
    }

    return get(scale, value, value)
}

export const widthTransformer = (value: TLengthStyledSystem, scale?: Scale) =>
    get(scale, value, !isNumber(value) || value > 1 ? value : `${value * 100}%`)
