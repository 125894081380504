import { contentPadding } from './constants/shared-styles'
import { Box } from '../box/box'
import { Text } from '../text/text'
import type { TextProps } from '../text/text'

type Props = Pick<TextProps, 'children'> & {
    title?: string
}

const stylesContent = {
    padding: contentPadding,
    color: 'textInverted',
    wordWrap: 'break-word',

    '& + &': {
        marginTop: 'x-small',
    },
} as const

export const InfoTipContent = ({ children, title }: Props) => (
    <Box css={stylesContent}>
        {title && (
            <Text textStyle="title" as="h3" display="block" fontWeight="bold" marginBottom="x-small">
                {title}
            </Text>
        )}
        <Text textStyle="description" color="textInverted">
            {children}
        </Text>
    </Box>
)
