import { system } from 'styled-system'

import type { CssGlobals } from '../../utils/css-unit'
import type { Config, ResponsiveValue } from '../styled-system-re-exported-types'

type SelfPosition = 'center' | 'end' | 'flex-end' | 'flex-start' | 'self-end' | 'self-start' | 'start'
type ContentDistribution = 'stretch' | 'space-around' | 'space-between' | 'space-evenly'
type ContentPosition = 'center' | 'end' | 'flex-end' | 'flex-start' | 'start'

export type AlignItems = CssGlobals | SelfPosition | 'baseline' | 'normal' | 'stretch'
export type JustifyItems = AlignItems | 'left' | 'right'
type AlignContent = CssGlobals | ContentDistribution | ContentPosition | 'baseline' | 'normal'
type JustifyContent = CssGlobals | ContentDistribution | ContentPosition | 'normal' | 'left' | 'right'
type FlexWrap = CssGlobals | 'nowrap' | 'wrap' | 'wrap-reverse'
type FlexDirection = CssGlobals | 'column' | 'column-reverse' | 'row' | 'row-reverse'

export type FlexProps = {
    alignItems?: ResponsiveValue<AlignItems>
    alignContent?: ResponsiveValue<AlignContent>
    justifyItems?: ResponsiveValue<JustifyItems>
    justifyContent?: ResponsiveValue<JustifyContent>
    flexWrap?: ResponsiveValue<FlexWrap>
    flexDirection?: ResponsiveValue<FlexDirection>
}

/** @public used in docs */
export const flexConfig = {
    alignItems: true,
    alignContent: true,
    justifyItems: true,
    justifyContent: true,
    flexWrap: true,
    flexDirection: true,
} as const satisfies Config<keyof FlexProps>

export const flex = system(flexConfig)
