import { useCallback } from 'react'

import type { Ref } from '../types'

const setRef = <T>(ref: Ref<T>, instance: T | null) => {
    if (ref) {
        if (typeof ref === 'function') {
            ref(instance)
        } else {
            ref.current = instance
        }
    }
}

/*
 * `useCallback` is used to prevent unnecessary
 * `useComposeRefs` calls.
 *
 * Read more:
 * https://reactjs.org/docs/refs-and-the-dom.html#caveats-with-callback-refs
 */
export const useComposeRefs = <T extends Element>(refA: Ref<T>, refB: Ref<T>) =>
    useCallback(
        (instance: T | null) => {
            setRef<T>(refA, instance)
            setRef<T>(refB, instance)
        },
        [refA, refB],
    )
