import { borders } from './tokens/borders'
import { breakpoints } from './tokens/breakpoints'
import type { Colors } from './tokens/colors'
import { colors } from './tokens/colors'
import { duration } from './tokens/duration'
import { fontSizes } from './tokens/font-sizes'
import { fontWeights } from './tokens/font-weights'
import { fonts } from './tokens/fonts'
import { grid } from './tokens/grid'
import { letterSpacings } from './tokens/letter-spacings'
import { lineHeights } from './tokens/line-heights'
import { mediaQueries } from './tokens/media-queries'
import { radii } from './tokens/radii'
import { shadows } from './tokens/shadows'
import { sideBorders } from './tokens/side-borders'
import { space } from './tokens/space'
import { timingFunction } from './tokens/timing-function'
import { zIndices } from './tokens/zindices'
import type { Theme } from './types'

export const createTheme = (overrides?: { colors: Partial<Colors> }): Theme => ({
    breakpoints,
    mediaQueries,
    fonts,
    fontSizes,
    fontWeights,
    lineHeights,
    letterSpacings,
    space,
    grid,
    zIndices,
    shadows,
    borders,
    sideBorders,
    radii,
    colors: { ...colors, ...overrides?.colors },
    timingFunction,
    duration,
})
