import type { ReactElement, ReactNode } from 'react'
import { useId, useRef, useState } from 'react'

import { arrow, flip, limitShift, offset, shift } from '@floating-ui/react'

import { contentFallbackPlacements } from './constants/content-fallback-placements'
import { InfoTipArrow } from './info-tip-arrow'
import { InfoTipBox } from './info-tip-box'
import type { Props as InfoTipTriggerProps } from './info-tip-trigger'
import { InfoTipTrigger } from './info-tip-trigger'
import { px } from '../../utils/css-unit'
import { useOffset } from '../../utils/use-modifiers'
import { backdropZIndex } from '../backdrop/backdrop'
import { Popover } from '../popover/popover'
import { PopoverContent } from '../popover/popover-content'
import { PopoverTrigger } from '../popover/popover-trigger'

type CommonProps = {
    autoFocus?: boolean
    children: ReactNode
    disabled?: boolean
    disablePortal?: boolean
    id?: string
    isInitiallyOpen?: boolean
    label?: string
}

type TriggerProps = CommonProps & {
    label?: never
    trigger: (props: InfoTipTriggerProps) => ReactElement
}

type LabelProps = CommonProps & {
    trigger?: never
}

export type Props = TriggerProps | LabelProps

export const InfoTipInternal = ({
    autoFocus = true,
    children,
    disabled,
    disablePortal = false,
    id,
    isInitiallyOpen = false,
    trigger,
    label,
}: Props) => {
    const generatedId = useId()
    const componentId = id ?? generatedId
    const arrowRef = useRef<HTMLElement>(null)

    const [isOpen, setIsOpen] = useState(!disabled && isInitiallyOpen)

    const handleTriggerOnClick = () => setIsOpen(!isOpen)

    const infoTipTriggerProps = {
        ariaExpanded: isOpen,
        ariaControls: componentId,
        disabled,
        onClick: () => {
            handleTriggerOnClick()
        },
    }

    return (
        <Popover
            id={componentId}
            open={isOpen}
            onOpenChange={setIsOpen}
            middleware={[
                flip({
                    fallbackPlacements: contentFallbackPlacements,
                }),
                shift({ limiter: limitShift() }),
                // eslint-disable-next-line react-compiler/react-compiler
                arrow({ element: arrowRef }),
                offset({ mainAxis: useOffset() }),
            ]}
            placement="top"
            disableBackdrop
            disablePortal={disablePortal}
            autoFocus={autoFocus}
        >
            <PopoverTrigger disabled={disabled}>
                {(triggerProps) =>
                    trigger ? (
                        trigger({ ...triggerProps, ...infoTipTriggerProps })
                    ) : (
                        <InfoTipTrigger label={label} {...triggerProps} {...infoTipTriggerProps} />
                    )
                }
            </PopoverTrigger>
            <PopoverContent zIndex={backdropZIndex}>
                {({ arrowX = 0, arrowY = 0, placement }) => (
                    <InfoTipBox>
                        <InfoTipArrow ref={arrowRef} placement={placement} left={px(arrowX)} top={px(arrowY)} />
                        {children}
                    </InfoTipBox>
                )}
            </PopoverContent>
        </Popover>
    )
}
