import type { Placement } from '@floating-ui/react'

export const contentFallbackPlacements: Array<Placement> = [
    'top',
    'right-start',
    'bottom',
    'left-start',
    'top-start',
    'top-end',
    'bottom-start',
    'bottom-end',
    'right',
    'right-end',
    'left',
    'left-end',
]
