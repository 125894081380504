import type { SpaceAccessPaths } from '../../theme/tokens/generated/token-access-paths'

export const sizeIconPaddingMap = {
    small: 'xx-small',
    medium: 'xx-small',
    large: 'xx-small',
} as const

export const sizeToButtonGroupIconButtonPaddingMap = {
    small: 'xx-small',
    medium: 'xx-small',
    large: 'x-small',
} as const

export const sizeToMinWidthMap = {
    small: '60px',
    medium: '80px',
    large: '100px',
} as const

export const sizeToFontSizeMap = {
    small: 'x-small',
    medium: 'small',
    large: 'medium',
} as const

export const sizeToIconSizeMap = {
    small: 'x-small',
    medium: 'small',
    large: 'small',
} as const

// This is a workaround for the fact that we don't have a 12px token
export const iconPaddingMap = {
    small: 'xx-small',
    medium: '12px' as SpaceAccessPaths,
    large: '12px' as SpaceAccessPaths,
} as const

// In case of medium Button with Icon and label provided, we need to change icon size from (small) 24px to (x-small) 16px for better visual alignment.
export const buttonWithLabelSizeToIconSizeMap = { ...sizeToIconSizeMap, medium: 'x-small' } as const
