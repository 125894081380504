import { forwardRef } from 'react'

import type { Props } from './dialog-internal'
import { DialogInternal } from './dialog-internal'
import { grid } from '../../theme/tokens/grid'

export const headerFooterHeight = 8 * grid

export const Dialog = forwardRef<HTMLElement, Props>(({ onKeyDown, ...props }, ref) => (
    <DialogInternal
        ref={ref}
        {...props}
        onKeyDown={(event) => {
            onKeyDown?.(event)

            if (event.code === 'Escape') {
                event.stopPropagation()
            }
        }}
    />
))

Dialog.displayName = 'Dialog'
