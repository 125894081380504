export const checkboxSizeMap = {
    'x-small': 'small',
    small: 'small',
    medium: 'small',
    large: 'medium',
    'x-large': 'large',
} as const

export const labelSizeMap = {
    'x-small': 'small',
    small: 'small',
    medium: 'medium',
    large: 'large',
    'x-large': 'large',
} as const

export const labelPaddingYMap = {
    'x-small': 'xx-small',
    small: 'xx-small',
    medium: 'none',
    large: 'xx-small',
    'x-large': 'x-small',
} as const
