import { useToaster } from 'react-hot-toast'

import { toastWidth } from './constants'
import { ToastWrapper } from './toast-wrapper'
import { Box } from '../box/box'

export const ToastProvider = () => {
    const { toasts, handlers } = useToaster()
    const { startPause, endPause, calculateOffset, updateHeight } = handlers

    return (
        <Box
            width={toastWidth}
            position="fixed"
            bottom="0px"
            left="50%"
            right="50%"
            onMouseEnter={startPause}
            onMouseLeave={endPause}
            css={{
                transform: 'translateX(-50%)',
                zIndex: 9999,
                '@keyframes toastEnter': {
                    from: { opacity: 0 },
                    to: { opacity: 1 },
                },
            }}
        >
            {toasts.map((toast) => (
                <ToastWrapper
                    key={toast.id}
                    toast={toast}
                    calculateOffset={calculateOffset}
                    updateHeight={updateHeight}
                />
            ))}
        </Box>
    )
}

ToastProvider.displayName = 'ToastProvider'
