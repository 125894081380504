import type { SchemaMapping, Size, Space, Variant } from '../../types'
import type { Props as BoxProps } from '../box/box'

type BaseIconProps = {
    size?: Size
    rotate?: number
    slot?: string
    tooltip?: string
} & Omit<BoxProps, 'as' | 'color'>

/*
 * PrivateIconProps are only used in the inner implementation
 * and are not available to the consumers.
 */
/** @public used in generated files */
export type PrivateIconProps = {
    viewBox?: string
}

type IconPropsWithVariant = {
    variant?: Variant | SchemaMapping | 'brand'
    color?: never
} & BaseIconProps

type IconPropsWithColor = Pick<BoxProps, 'color'> & { variant?: never } & BaseIconProps

/** @public used in generated files */
export type IconProps = IconPropsWithVariant | IconPropsWithColor
export type IconStaticProps = BaseIconProps

export const iconSizeMap: Record<Size, Space> = {
    'x-small': 'small',
    small: 'medium',
    medium: 'large',
    large: 'x-large',
    'x-large': 'xx-large',
}
