import type { PixelValue } from '../../utils/css-unit'
import { px } from '../../utils/css-unit'

const definition = {
    'xx-small': px(10),
    'x-small': px(12),
    small: px(14),
    medium: px(16),
    large: px(20),
    'x-large': px(24),
    'xx-large': px(40),
} satisfies Record<string, PixelValue>

export const fontSizes: { [K in keyof typeof definition]: PixelValue } = definition
