import { forwardRef } from 'react'

import { headerFooterHeight } from './dialog'
import type { Props as BoxProps } from '../box/box'
import { Box } from '../box/box'

type Props = BoxProps

export const DialogFooter = forwardRef<HTMLElement, Props>((props, ref) => (
    <Box ref={ref} minHeight={headerFooterHeight} paddingX="medium" display="flex" alignItems="center" {...props} />
))

DialogFooter.displayName = 'DialogFooter'
