import { forwardRef } from 'react'

import type { IconButtonProps } from './types'
import { getIconButtonProps } from './utils'
import { ButtonMinimal } from '../button/button-minimal'
import { TooltipInternal } from '../tooltip-internal/tooltip-internal'

type Props = IconButtonProps & {
    isTooltipVisible?: boolean
}

/**
 * IconButtonMinimalCore component is used only for internal usage in the design
 * system. It provides more properties that are used internally and contain
 * core functionalities of the component. Please use the IconButtonMinimal
 * component which contains the final consumer api of the IconButtonMinimal
 * component.
 */

export const IconButtonMinimalCore = forwardRef<HTMLButtonElement, Props>(
    ({ label, isTooltipVisible, ...props }, ref) => (
        <TooltipInternal title={label} visible={isTooltipVisible}>
            <ButtonMinimal ref={ref} aria-label={label} {...getIconButtonProps(props)} />
        </TooltipInternal>
    ),
)

IconButtonMinimalCore.displayName = 'IconButtonMinimalCore'
