import { system } from 'styled-system'

import type { ColorsAccessPaths } from '../../theme/tokens/generated/token-access-paths'
import type { CssGlobals } from '../../utils/css-unit'
import type { Config, ResponsiveValue } from '../styled-system-re-exported-types'

export type ColorProps = {
    color?: ColorsAccessPaths | CssGlobals
    backgroundColor?: ColorsAccessPaths | CssGlobals
    opacity?: ResponsiveValue<number>
}

/** @public used in docs */
export const colorConfig = {
    color: {
        property: 'color',
        scale: 'colors',
    },
    backgroundColor: {
        property: 'backgroundColor',
        scale: 'colors',
    },
    opacity: true,
} as const satisfies Config<keyof ColorProps>

export const color = system(colorConfig)
