import type { ComponentProps, ReactElement } from 'react'

import type { CSSObject } from '@styled-system/css'

import { DrawerContainer } from './drawer-container'
import { ThemeProvider } from '../../theme-provider'
import { useTheme } from '../../utils/use-theme'
import { Box } from '../box/box'
import { DialogBody } from '../dialog/dialog-body'
import { DialogFooter } from '../dialog/dialog-footer'
import { DialogHeader } from '../dialog/dialog-header'
import { DialogTitle } from '../dialog/dialog-title'
import { Separator } from '../separator/separator'
import { Stack } from '../stack/stack'

type SizeType = 'small' | 'medium' | 'large' | 'max' | '20%' | '35%' | '50%' | '75%' | '90%' | '100%'

export type Props = {
    padding?: ComponentProps<typeof Box>['padding']
    title: string
    header?: ReactElement
    subHeader?: ReactElement
    size?: SizeType
    headerTheme?: ReturnType<typeof useTheme>
    footer?: ReactElement
    /**
     * Callback fired when component requests to be closed.
     */
    onClose: () => void
} & Omit<ComponentProps<typeof DrawerContainer>, 'onClose'>

const SIZES_WIDTH = {
    small: 400,
    medium: 600,
    large: 800,
    max: 900,
}

const SIZE_MAPPING_WIDTH = {
    small: { width: SIZES_WIDTH.small },
    medium: { width: SIZES_WIDTH.medium },
    large: { width: SIZES_WIDTH.large },
    max: { width: SIZES_WIDTH.max },
    '20%': { width: '20%', minWidth: SIZES_WIDTH.small },
    '35%': { width: '35%', minWidth: SIZES_WIDTH.medium },
    '50%': { width: '50%', minWidth: SIZES_WIDTH.medium },
    '75%': { width: '75%', minWidth: SIZES_WIDTH.large },
    '90%': { width: '90%', minWidth: SIZES_WIDTH.max },
    '100%': { width: '100%', minWidth: SIZES_WIDTH.max },
} satisfies Record<SizeType, CSSObject>

const SIZES_HEIGHT = {
    small: 280,
    medium: 400,
    large: 600,
    max: 600,
}

const SIZE_MAPPING_HEIGHT = {
    small: { height: SIZES_HEIGHT.small },
    medium: { height: SIZES_HEIGHT.medium },
    large: { height: SIZES_HEIGHT.large },
    max: { height: SIZES_HEIGHT.max },
    '20%': { height: '20%', minHeight: SIZES_HEIGHT.small },
    '35%': { height: '35%', minHeight: SIZES_HEIGHT.medium },
    '50%': { height: '50%', minHeight: SIZES_HEIGHT.medium },
    '75%': { height: '75%', minHeight: SIZES_HEIGHT.large },
    '90%': { height: '90%', minHeight: SIZES_HEIGHT.max },
    '100%': { height: '100%', minHeight: SIZES_HEIGHT.max },
} satisfies Record<SizeType, CSSObject>

export const getSizeStyles = ({
    placement,
    size,
}: {
    placement: Props['placement']
    size: NonNullable<Props['size']>
}) => ({
    ...(placement === 'bottom'
        ? { ...SIZE_MAPPING_HEIGHT[size], width: '100%' }
        : { ...SIZE_MAPPING_WIDTH[size], height: 'auto' }),
})

export const DrawerInternal = ({
    children,
    size = 'small',
    placement,
    header,
    footer,
    padding,
    title,
    subHeader,
    onClose,
    onDismiss,
    headerTheme,
    css,
    ...containerProps
}: Props) => {
    const theme = useTheme()

    return (
        <DrawerContainer
            {...containerProps}
            placement={placement}
            css={{
                display: 'flex',
                flexDirection: 'column',
                ...getSizeStyles({ placement, size }),
                ...css,
            }}
            onClose={onClose}
            onDismiss={onDismiss}
        >
            <Box position="sticky" top={0} zIndex={1}>
                <ThemeProvider theme={headerTheme ?? theme}>
                    <DialogHeader
                        onClose={onClose}
                        aria-label={header ? title : undefined}
                        backgroundColor="background"
                    >
                        {header ?? <DialogTitle>{title}</DialogTitle>}
                    </DialogHeader>
                    <Separator backgroundColor="background" />
                </ThemeProvider>

                {subHeader && (
                    <>
                        <Box paddingY="small" paddingX="medium" backgroundColor="backgroundSecondary">
                            {subHeader}
                        </Box>
                        <Separator />
                    </>
                )}
            </Box>
            <DialogBody padding={padding} scrollableContent flex={1}>
                {children}
            </DialogBody>
            {footer && (
                <Stack spacing="none" backgroundColor="background" position="sticky" bottom={0} zIndex={1}>
                    <Separator />
                    <DialogFooter>{footer}</DialogFooter>
                </Stack>
            )}
        </DrawerContainer>
    )
}

DrawerInternal.displayName = 'Drawer'
