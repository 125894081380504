import { isEmpty, last } from 'remeda'
import { z } from 'zod'

const apiErrorSchema = z.object({
    detail: z.string().optional(),
    errors: z
        .array(
            z.object({
                id: z.string().uuid(),
                title: z.string(),
                detail: z.string().optional(),
                meta: z.object({ loc: z.array(z.string()).optional(), msg: z.string(), type: z.string() }),
            }),
        )
        .optional(),
})

const transformationsApiErrorSchema = z.union([
    z.object({
        name: z.array(z.string()),
    }),
    z.object({
        errors: z.array(
            z.object({
                id: z.string(),
                title: z.string(),
                detail: z.string().optional(),
            }),
        ),
    }),
    z.object({
        name: z.string(),
        instructions: z.array(z.string()),
    }),
    z.object({
        name: z.string(),
    }),
    z.object({
        instructions: z.array(z.string()),
    }),
])

const getFormErrors = (errors: z.infer<typeof apiErrorSchema>['errors']) => {
    if (!errors || errors.length === 0) {
        return undefined
    }

    return errors.map((error) => ({
        key: last(error.meta.loc ?? []) ?? 'root.server',
        type: error.meta.type,
        message: error.meta.msg,
    }))
}

const extractTransformationsErrors = (errors: z.infer<typeof transformationsApiErrorSchema>) => {
    let message: string | undefined

    const formErrors =
        'instructions' in errors
            ? errors.instructions.map((instruction, index) => ({
                  key: String(index),
                  type: 'error',
                  message: instruction,
              }))
            : []

    if ('name' in errors) {
        message = Array.isArray(errors.name) ? errors.name[0] : errors.name
    }

    if ('errors' in errors) {
        message = errors.errors.find((error) => error.detail)?.detail
    }

    return {
        message,
        formErrors,
    }
}

export const parseErrors = (errorResponse: unknown) => {
    const error = apiErrorSchema.safeParse(errorResponse)

    if (error.success && !isEmpty(error.data)) {
        return {
            message: error.data.detail,
            formErrors: getFormErrors(error.data.errors),
        }
    }

    const transformationsError = transformationsApiErrorSchema.safeParse(errorResponse)

    if (transformationsError.success) {
        return extractTransformationsErrors(transformationsError.data)
    }

    return {
        message: undefined,
        formErrors: undefined,
    }
}

type FormError = {
    key: string
    type: string | undefined
    message: string
}

export class ApiError extends Error {
    readonly status: number

    readonly formErrors: Array<FormError>

    readonly originalError: unknown

    constructor({
        status,
        message,
        formErrors = [],
        originalError,
    }: {
        status: number
        message: string
        formErrors?: Array<FormError>
        originalError?: Error
    }) {
        const errorMessage = message.startsWith('<') ? 'Service unavailable' : message || 'Something went wrong'

        super(errorMessage)
        this.status = status
        this.originalError = originalError
        this.formErrors = formErrors
        this.name = 'ApiError'
        Object.setPrototypeOf(this, new.target.prototype)
    }
}
