import { forwardRef } from 'react'

import { ButtonBase } from './button-base'
import type { Props as ButtonBaseProps } from './button-base'

type Props = Omit<ButtonBaseProps, 'color' | 'backgroundColor'>

export const ButtonSecondary = forwardRef<HTMLButtonElement, Props>(({ variant = 'brand', css, ...props }, ref) => {
    const borderAndTextColor = variant === 'danger' ? 'dangerActive' : variant

    return (
        <ButtonBase
            ref={ref}
            backgroundColor="transparent"
            borderColor={borderAndTextColor}
            color={borderAndTextColor}
            variant={variant}
            isButtonWithLabel
            css={[
                {
                    '&:hover': {
                        ':not([aria-disabled="true"])': {
                            backgroundColor:
                                variant === 'danger' ? `${variant}Secondary` : `${variant}SecondaryHighlighted`,
                            borderColor: borderAndTextColor,
                            color: borderAndTextColor,
                        },
                    },
                    '&:focus': {
                        color: variant,
                    },
                    '&:active': {
                        ':not([aria-disabled="true"])': {
                            backgroundColor:
                                variant === 'danger' ? `${variant}SecondaryHighlighted` : `${variant}SecondaryActive`,
                            borderColor: borderAndTextColor,
                            color: borderAndTextColor,
                        },
                    },
                    '&[aria-disabled="true"]': props.isLoading
                        ? /**
                           * Here an empty object needs to be passed, otherwise, the styling from the
                           * `ButtonBase` component won't be inherited.
                           */
                          {}
                        : {
                              backgroundColor: 'backgroundEmphasis',
                              color: 'textMuted',
                              borderColor: 'backgroundEmphasis',
                          },
                },
                css,
            ]}
            {...props}
        />
    )
})

ButtonSecondary.displayName = 'ButtonSecondary'
