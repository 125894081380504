import type { PixelValue } from '../../utils/css-unit'
import { px } from '../../utils/css-unit'

const definition = {
    none: px(0),
    medium: px(5),
    large: px(10),
    round: px(9999),
} satisfies Record<string, PixelValue>

export const radii: { [K in keyof typeof definition]: PixelValue } = definition
