import type { ReactElement } from 'react'

import type { Props as ButtonBaseProps } from './button-base'
import { sizeIconPaddingMap } from './size-mapping'
import type { LimitedSize } from '../../types'
import { Box } from '../box/box'

type PrefixSuffixType = ReactElement

export type PrefixAndSuffixAware = {
    prefix?: PrefixSuffixType
    suffix?: PrefixSuffixType
}

type PrefixSuffixProps = {
    actualSize: LimitedSize
    children: PrefixSuffixType
}

const PrefixSuffix = ({ children, actualSize }: PrefixSuffixProps) => (
    <Box as="span" display="inline-flex" css={{ gap: sizeIconPaddingMap[actualSize] }}>
        {children}
    </Box>
)

type ConditionalAddPrefixSuffixProps = Pick<ButtonBaseProps, 'children'> &
    PrefixAndSuffixAware & {
        actualSize: LimitedSize
    }

export const ConditionalAddPrefixSuffix = ({
    actualSize,
    prefix,
    suffix,
    children,
}: ConditionalAddPrefixSuffixProps) => (
    <>
        {prefix && <PrefixSuffix actualSize={actualSize}>{prefix}</PrefixSuffix>}
        {suffix || prefix ? <Box as="span">{children}</Box> : children}
        {suffix && <PrefixSuffix actualSize={actualSize}>{suffix}</PrefixSuffix>}
    </>
)
